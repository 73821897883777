import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { QuestionText } from '../globalStyle';


const ChoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90%;
  margin-bottom: 15px;
`;

const ChoiceButton = styled.button`
  background-color: ${props => props.selected ? '#4A90E2' : '#f0f4f8'};
  color: ${props => props.selected ? 'white' : '#333'};
  border: 1px solid #ccc;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: ${props => props.selected ? '#357ABD' : '#E8F0FE'};
  }
`;

const CustomInputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const CustomInput = styled.input`
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
`;

const AddButton = styled.button`
  background-color: #4A90E2;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: #357ABD;
  }
`;

const SingleChoice = ({ questionText, choices, answers, setAnswers, questionId, useCustomChoice = false }) => {
    const [selectedChoice, setSelectedChoice] = useState('');
    const [customChoice, setCustomChoice] = useState('');
    const [allChoices, setAllChoices] = useState(choices);

    useEffect(() => {
        if (answers[questionId]) {
            setSelectedChoice(answers[questionId]);
        }
    }, [answers, questionId]);

    const handleChoiceSelect = (choice) => {
        if (selectedChoice === choice) {
            // If the same choice is clicked twice, deselect it
            setSelectedChoice('');
            // setAnswers(prev => ({ ...prev, [questionId]: '' }));
            setAnswers(prev => {
                const newAnswers = { ...prev };
                delete newAnswers[questionId];
                return newAnswers;
            });
        } else {
            setSelectedChoice(choice);
            setAnswers(prev => ({ ...prev, [questionId]: choice }));
        }
    };

    const handleAddCustomChoice = () => {
        if (customChoice && !allChoices.includes(customChoice)) {
            setAllChoices(prev => [...prev, customChoice]);
            setCustomChoice('');
        }
    };

    return (
        <>
            <QuestionText>{questionText}</QuestionText>
            <ChoiceContainer>
                {allChoices.map((choice, index) => (
                    <ChoiceButton
                        key={index}
                        selected={selectedChoice === choice}
                        onClick={() => handleChoiceSelect(choice)}
                    >
                        {choice}
                    </ChoiceButton>
                ))}
                {useCustomChoice && (
                    <CustomInputContainer>
                        <CustomInput
                            type="text"
                            value={customChoice}
                            onChange={(e) => setCustomChoice(e.target.value)}
                            placeholder="Others"
                        />
                        <AddButton onClick={handleAddCustomChoice}>Add</AddButton>
                    </CustomInputContainer>
                )}
            </ChoiceContainer>
        </>
    );
};

export default SingleChoice;