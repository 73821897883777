import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { QuestionText } from '../globalStyle';

const SortableTaskList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  max-width: 30%;
`;

const TaskItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  cursor: move;
`;

const TaskName = styled.span`
  flex-grow: 1;
  font-size: 0.8rem;
  text-align: center;
`;

const MoveButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 0 5px;
`;



const SortableTasks = ({ tasks, setTasks, title }) => {
  const { t } = useTranslation();

  const handleMoveTask = (index, direction) => {
    const newTasks = [...tasks];
    if (direction === 'up' && index > 0) {
      [newTasks[index], newTasks[index - 1]] = [newTasks[index - 1], newTasks[index]];
    } else if (direction === 'down' && index < newTasks.length - 1) {
      [newTasks[index], newTasks[index + 1]] = [newTasks[index + 1], newTasks[index]];
    }
    setTasks(newTasks);
  };

  return (
    <>
      <QuestionText>{title}</QuestionText>
      <SortableTaskList>
        {tasks.map((task, index) => (
          <TaskItem key={index}>
            <MoveButton onClick={() => handleMoveTask(index, 'up')} disabled={index === 0}>▲</MoveButton>
            <TaskName>{task}</TaskName>
            <MoveButton onClick={() => handleMoveTask(index, 'down')} disabled={index === tasks.length - 1}>▼</MoveButton>
          </TaskItem>
        ))}
      </SortableTaskList>
    </>
  );
};

export default SortableTasks;