import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Title, AgreeButton, Subtitle } from '../globalStyle';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend, SubTitle } from 'chart.js';
import SortableTasks from '../components/SortableTasks';
import Matrix from '../components/Matrix';
import RadarChartSection from '../components/RadarChartSection';
import SingleChoice from '../components/SingleChoice';

import { saveEvaluationInputs } from '../utils/storage';
import { isHumanSetting } from '../utils/userSettings';
import { useButtonTracking } from '../hooks/useButtonTracking';

ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
);

const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
  border-radius: 8px;
  width: 70vw;
  padding: 20px;
  overflow-y: auto;
`;

const SectionContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 95%;
`;

const Evaluation = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const [tasks, setTasks] = useState([]);
    const [sortedTasks, setSortedTasks] = useState([]);
    const [userSetting, setUserSetting] = useState('');
    const trackButtonClick = useButtonTracking();
    const currentLang = sessionStorage.getItem('language');

    const [radarDataFeatures, setRadarDataFeatures] = useState({
        labels: currentLang === "en" ? ['Task Enjoyment', 'Task Difficulty', 'Task Novelty', 'Just Intuition', ''] : ['任务的有趣性', '任务的难度', '任务的新颖性', '自己的直觉', ''],
        datasets: [{
            data: [1, 1, 1, 1],
            backgroundColor: 'rgba(74, 144, 226, 0.2)',
            borderColor: 'rgba(74, 144, 226, 1)',
            pointBackgroundColor: 'rgba(74, 144, 226, 1)',
        }]
    });
    const [radarDataStrategy, setRadarDataStrategy] = useState({
        labels: currentLang === "en" ? ['Select objects before thinking about tasks', 'Think about task type before selecting objects', 'Just Intuition', ''] : ['先选择物体，再想具体任务', '先想任务类型，再选择物体', '直觉联想', ''],
        datasets: [{
            data: [1, 1, 1],
            backgroundColor: 'rgba(74, 144, 226, 0.2)',
            borderColor: 'rgba(74, 144, 226, 1)',
            pointBackgroundColor: 'rgba(74, 144, 226, 1)',
        }]
    });

    const [matrixAnswersLikelihood, setMatrixAnswersLikelihood] = useState({});
    const [matrixAnswersNovelty, setMatrixAnswersNovelty] = useState({});
    const [singleChoiceAnswersNotice, setSingleChoiceAnswersNotice] = useState({});
    const [singleChoiceAnswersDegree, setSingleChoiceAnswersDegree] = useState({});
    const [isAllCompleted, setIsAllCompleted] = useState(false);

    const noticeChoices = (currentLang === "en") ? ["Yes", "No"] : ["是", "否"];
    const considerScales = (currentLang === "en") ? 'Very little,Little,Somewhat little,Moderate,Somewhat much,Much,Very much'.split(',') : '非常少,很少,比较少,中等,比较多,很多,非常多'.split(',');

    useEffect(() => {
        const currentLang = sessionStorage.getItem('language');
        if (currentLang) {
            i18n.changeLanguage(currentLang);
        }

    }, [i18n]);


    useEffect(() => {
        const tasks = JSON.parse(sessionStorage.getItem('completedTaskNames')) || [];
        const storedTasks = JSON.parse(sessionStorage.getItem('completedTaskNames')) || [];

        setSortedTasks([...storedTasks].sort(() => Math.random() - 0.5));
        setTasks(tasks);

        const storedRadarDataFeatures = JSON.parse(sessionStorage.getItem('radarChartDataFeatures'));
        if (storedRadarDataFeatures) {
            setRadarDataFeatures(storedRadarDataFeatures);
        }

        const storedRadarDataStrategy = JSON.parse(sessionStorage.getItem('radarChartDataStrategy'));
        if (storedRadarDataStrategy) {
            setRadarDataStrategy(storedRadarDataStrategy);
        }

        const initialMatrixAnswersNovelty = {};
        const initialMatrixAnswersLikelihood = {};
        tasks.forEach(task => {
            initialMatrixAnswersNovelty[`novelty_${task}`] = ''; // add matrix type before the task name
            initialMatrixAnswersLikelihood[`likelihood_${task}`] = ''; // add matrix type before the task name
        });
        setMatrixAnswersNovelty(initialMatrixAnswersNovelty);
        setMatrixAnswersLikelihood(initialMatrixAnswersLikelihood);


        const storedUserSetting = sessionStorage.getItem('userSetting');
        setUserSetting(storedUserSetting);


        const radarDataFeatures = ({
            labels: currentLang === "en" ? ['Task Enjoyment', 'Task Difficulty', 'Task Novelty', 'Just Intuition'] : ['任务的有趣性', '任务的难度', '任务的新颖性', '自己的直觉'],
            datasets: [{
                data: [0, 0, 0, 0],
                backgroundColor: 'rgba(74, 144, 226, 0.2)',
                borderColor: 'rgba(74, 144, 226, 1)',
                pointBackgroundColor: 'rgba(74, 144, 226, 1)',
            }]
        });
        setRadarDataFeatures(radarDataFeatures);

        const radarDataStrategy = ({
            labels: currentLang === "en" ? ['Select objects before thinking about tasks', 'Think about task type before selecting objects', 'Just Intuition'] : ['先选择物体，再想具体任务', '先想任务类型，再选择物体', '直觉联想'],
            datasets: [{
                data: [0, 0, 0],
                backgroundColor: 'rgba(74, 144, 226, 0.2)',
                borderColor: 'rgba(74, 144, 226, 1)',
                pointBackgroundColor: 'rgba(74, 144, 226, 1)',
            }]
        });
        setRadarDataStrategy(radarDataStrategy);


    }, []);

    useEffect(() => {
        const isMatrixLikelihoodCompleted = Object.values(matrixAnswersLikelihood).every(answer => answer !== '');
        const isMatrixNoveltyCompleted = Object.values(matrixAnswersNovelty).every(answer => answer !== '');
        const isRadarFeaturesCompleted = radarDataFeatures.datasets[0].data.some(value => value !== 0);
        const isRadarStrategyCompleted = radarDataStrategy.datasets[0].data.some(value => value !== 0);
        const isSingleChoiceNoticeCompleted = Object.keys(singleChoiceAnswersNotice).length === 1;
        const isSingleChoiceDegreeCompleted = Object.keys(singleChoiceAnswersDegree).length === 1;

        setIsAllCompleted(isMatrixLikelihoodCompleted && isMatrixNoveltyCompleted && isRadarFeaturesCompleted && isRadarStrategyCompleted && isSingleChoiceNoticeCompleted && isSingleChoiceDegreeCompleted);
    }, [matrixAnswersLikelihood, matrixAnswersNovelty, radarDataFeatures, radarDataStrategy, singleChoiceAnswersNotice, singleChoiceAnswersDegree]);




    const handleProceed = () => {
        if (isAllCompleted) {
            const radarDataFeaturesData = {
                labels: radarDataFeatures.labels,
                data: radarDataFeatures.datasets[0].data
            };
            const radarDataStrategyData = {
                labels: radarDataStrategy.labels,
                data: radarDataStrategy.datasets[0].data
            };

            const evaluationInputs = {
                sortedTasks,
                radarDataFeaturesData,
                radarDataStrategyData,
                matrixAnswersLikelihood,
                matrixAnswersNovelty,
                singleChoiceAnswersNotice,
                singleChoiceAnswersDegree,
            }
            saveEvaluationInputs(evaluationInputs);
            trackButtonClick('evaluation');

            navigate('/feedback');
        }

    };


    return (
        <Container>
            <ScrollableContainer>
                <Subtitle>{t('evaluation')} {tasks.join(', ')}</Subtitle>
                <SectionContainer>
                    <SortableTasks
                        tasks={sortedTasks}
                        setTasks={setSortedTasks}
                        title={t('evalSortTaskTitle')}
                    />
                </SectionContainer>

                <SectionContainer>
                    <RadarChartSection
                        radarData={radarDataFeatures}
                        setRadarData={setRadarDataFeatures}
                        title={t('evalCharacteristicsTitle')}
                    />
                </SectionContainer>

                <SectionContainer>
                    <RadarChartSection
                        radarData={radarDataStrategy}
                        setRadarData={setRadarDataStrategy}
                        title={t('evalStrategyTitle')}
                    />
                </SectionContainer>

                <SectionContainer>
                    <Matrix
                        tasks={tasks}
                        matrixAnswers={matrixAnswersNovelty}
                        setMatrixAnswers={setMatrixAnswersNovelty}
                        title={t('evalNoveltyTitle')}
                        scaleLabels={t('evalNoveltyScales').split(',')}
                        matrixType="novelty"
                    />
                </SectionContainer>

                <SectionContainer>
                    <Matrix
                        tasks={tasks}
                        matrixAnswers={matrixAnswersLikelihood}
                        setMatrixAnswers={setMatrixAnswersLikelihood}
                        title={t('evalLikelihoodTitle')}
                        scaleLabels={t('evalLikelihoodScales').split(',')}
                        matrixType="likelihood"
                    />
                </SectionContainer>

                <SectionContainer>
                    <SingleChoice
                        questionText={isHumanSetting(userSetting) ? t('evalNoticeOtherPeopleTitle') : t('evalNoticeDummyTitle')}
                        choices={noticeChoices}
                        answers={singleChoiceAnswersNotice}
                        setAnswers={setSingleChoiceAnswersNotice}
                        questionId="q1"
                    />
                </SectionContainer>

                <SectionContainer>
                    <SingleChoice
                        questionText={isHumanSetting(userSetting) ? t('evalConsiderOtherPeopleTitle') : t('evalConsiderDummyTitle')}
                        choices={considerScales}
                        answers={singleChoiceAnswersDegree}
                        setAnswers={setSingleChoiceAnswersDegree}
                        questionId="q2"
                    />
                </SectionContainer>

                <AgreeButton disabled={!isAllCompleted} onClick={handleProceed}>
                    {t('finish')}
                </AgreeButton>
            </ScrollableContainer>



        </Container>
    );
};

export default Evaluation;