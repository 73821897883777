import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Consent from './pages/Consent';
import SurveyIntro from './pages/SurveyIntro';
import Survey from './pages/Survey';
import SurveyCompleted from './pages/SurveyCompleted';
import Quiz from './pages/Quiz';
import Task from './pages/Task';
import ViewObjects from './pages/ViewObjects';
import Evaluation from './pages/Evaluation';
import ThankYou from './pages/ThankYou';
import PersonalInfo from './pages/PersonalInfo';
import Home from './pages/Home';
import Feedback from './pages/Feedback';
import End from './pages/End';
import { TrackingProvider } from './contexts/TrackingContext';
import { useFlowControl } from './hooks/useFlowControl';
import { useLocation } from 'react-router-dom';

const FlowControlWrapper = ({ children }) => {
  useFlowControl();
  return children;
};

function App() {
  return (
    <TrackingProvider>
      <Router>
        <FlowControlWrapper>
          <div className="App">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/consent" element={<Consent />} />
              <Route path="/personalinfo" element={<PersonalInfo />} />
              <Route path="/survey-intro" element={<SurveyIntro />} />
              <Route path="/survey/:surveyId" element={<Survey />} />
              <Route path="/survey-completed" element={<SurveyCompleted />} />
              <Route path="/quiz" element={<Quiz />} />
              <Route path="/task" element={<Task />} />
              <Route path="/view-objects" element={<ViewObjects />} />
              <Route path="/evaluation" element={<Evaluation />} />
              <Route path="/thank-you" element={<ThankYou />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route path="/end" element={<End />} />
            </Routes>
          </div>
        </FlowControlWrapper>
      </Router>
    </TrackingProvider>
  );
}

export default App;