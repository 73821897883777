import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Container, Title } from '../globalStyle';
import { saveEndingStatus, saveIPAddress } from '../utils/storage';
import { sendSessionData } from '../utils/api';
import LoadingSpinner from '../components/LoadingSpinner';
const Button = styled.button`
    background-color: #4A90E2;
    color: white;
    font-size: 1rem;
    margin-top: 6vh;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 150px;
    &:hover {
        background-color: #357ABD;
    }
`;

const Message = styled.p`
    margin-top: 20px;
    font-size: 1rem;
    color: ${props => props.error ? 'red' : 'green'};
`;

const ThankYou = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const completionCode = 'C1M5JKWO';
    const redirectUrl = 'https://app.prolific.com/submissions/complete?cc=C1M5JKWO';
    const currentLang = sessionStorage.getItem('language');


    useEffect(() => {
        const sendData = async () => {
            try {
                const response = await fetch('https://api.ipify.org?format=json');
                const data = await response.json();
                const ipAddress = data.ip;

                saveEndingStatus('succeed');
                saveIPAddress(ipAddress);

                await sendSessionData(sessionStorage);
                setIsLoading(false);

                // redirect for english user only
                if (currentLang === "en") {
                    setTimeout(() => window.location.replace(redirectUrl), 6000);
                }
            } catch (error) {
                setError('Failed to send session data. Please download your data using the button below.');
                setIsLoading(false);
            }
        };

        sendData();
    }, [navigate]);

    const handleDownload = () => {
        const sessionData = JSON.stringify(sessionStorage);
        const blob = new Blob([sessionData], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'session_data.json';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };


    if (currentLang === 'en') {
        return (
            <Container>
                <Title>Thank you for your participation!</Title>
                {isLoading ? (
                    <>
                        <LoadingSpinner />
                        <Message>Please wait while we upload your data...</Message>
                    </>
                ) : error ? (
                    <>
                        <Message error>Data upload failed. Please download your data using the button below. Your completion code is {completionCode}.</Message>
                        <Button onClick={handleDownload}>Download data</Button>
                    </>
                ) : (
                    <Message>Data uploaded successfully! Your completion code is {completionCode}. You will be redirected to Prolific in a moment...</Message>
                )}
            </Container>
        );
    } else {
        return (
            <Container>
                <Title>感谢您的参与！</Title>
                {isLoading ? (
                    <>
                        <LoadingSpinner />
                        <Message>请等待，我们正在上传您的数据...</Message>
                    </>
                ) : error ? (
                    <>
                        <Message error>数据上传失败。请使用下面的按钮下载您的数据。</Message>
                        <Button onClick={handleDownload}>下载数据</Button>
                    </>
                ) : (
                    <Message>数据上传成功！</Message>
                )}
            </Container>
        );
    }
};

export default ThankYou;