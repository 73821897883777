import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Container, Subtitle, Title, AgreeButton, ContentContainer, Button, StyledMarkdown } from '../globalStyle';
import { saveTaskInputs, saveCompletedTaskName, getCompletedTaskNames, clearCompletedTaskNames } from '../utils/storage';
import { useButtonTracking } from '../hooks/useButtonTracking';
import { getObjects } from '../utils/storage';

// Styled components for layout
const TaskContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  margin-top: 20px;
  height: 80vh;
  width: 90vw;

  @media (max-width: 768px) {
    flex-direction: column;
    height: 100vh;
    width: 100vw;
  }
`;

const LeftSection = styled.div`
  width: 36%;
  border-radius: 8px;
  overflow-y: auto;
  padding: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const RightSection = styled.div`
  width: 50%;
  overflow-y: auto;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const RightSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const IconGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(100px, 1fr));
  gap: 15px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  overflow-y: auto;
  margin: 0 auto;
  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(40px, 1fr));
    gap: 10px;
  }
`;

const IconItem = styled.div`
  text-align: center;
  cursor: ${props => props.$clickable ? 'pointer' : 'default'};
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 8px;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${props => props.$clickable ? '#E8F0FE' : 'transparent'};
    transform: ${props => props.$clickable ? 'scale(1.05)' : 'none'};
  }
`;

const IconImage = styled.img`
  width: 45px;
  height: 45px;
  margin-bottom: 10px;
`;

const IconText = styled.div`
  font-size: 0.7rem;
  color: #333;
  margin-bottom: 1px;
`;

const Instruction = styled.p`
  font-size: 1rem;
  margin-bottom: 20px;
  color: #333;
`;

const StartButton = styled.button`
  background-color: #4A90E2;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #357ABD;
  }
`;

const InputField = styled.input`
  width: 80%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.8rem;
`;

const TextArea = styled.textarea`
  width: 80%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.8rem;
  min-height: 50px;
  resize: vertical;
`;


const InputLabel = styled.div`
  font-size: 1rem;
  color: #333;
  
  strong {
    font-weight: bold;
  }

  ul, ol {
    margin-left: 20px;
    margin-bottom: 10px;
  }

  li {
    margin-bottom: 5px;
  }
`;

const Select = styled.select`
  width: 20%;
  padding: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 0.8rem;
`;

const ScoreInputContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  align-items: center;
`;

const ScoreInput = styled(InputField)`
  width: 50px;
`;

const NeededObjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  width: 80%;
  margin-bottom: 15px;
  min-height: ${props => props.$isEmpty ? '50px' : 'auto'};
  padding: ${props => props.$isEmpty ? '10px' : '0'};
`;

const NeededObjectContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 10px;
`;

const NeededObjectItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  border: 2px solid #ccc;
  padding: 8px;
  position: relative;
`;

const NeededObjectImage = styled.img`
  width: 30px;
  height: 30px;
  margin-bottom: 8px;
`;

const NeededObjectCountInput = styled.input`
  width: 25px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 5px;
  text-align: center;
`;

const DeleteButton = styled.button`
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #ff4d4f;
  color: white;
  border: none;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 0.8rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #ff7875;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const ExitButton = styled.button`
  background-color: #4A90E2;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer; 
  transition: background-color 0.3s ease;
  margin-left: 10px;

  &:hover {
    background-color: #ff7875;
  }
`;

const InstructionButton = styled.button`
    position: fixed;
    left: 2vw;
    font-size: 0.9rem;
    top: 50%;
    padding: 5px 5px;

    transform: translateY(-50%) rotate(-90deg);
    transform-origin: left center;
    background-color: #4A90E2;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100px;
    &:hover {
        background-color: #357ABD;
    }

    color: white;
    z-index: 1000;
`;

const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
`;

const ModalContent = styled.div`
    background-color: white;
    padding: 40px;
    border-radius: 5px;
    max-width: 50%;
    max-height: 80%;
    overflow-y: auto;
`;

const SideButtonContainer = styled.div`
  position: fixed;
  left: 2vw;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SideButton = styled.button`
  font-size: 0.7rem;
  padding: 5px;
  background-color: #4A90E2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: white;
  width: 5vw;
  &:hover {
    background-color: #357ABD;
  }
`;

const ImageModal = styled(Modal)`
  img {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
  }
`;

const Task = () => {
    const { t, i18n } = useTranslation();
    const [objects, setObjects] = useState([]);
    const [isCreating, setIsCreating] = useState(false);
    const [taskCount, setTaskCount] = useState(0);
    const [taskName, setTaskName] = useState('');
    const [taskDescription, setTaskDescription] = useState('');
    const [detailedSetting, setDetailedSetting] = useState('');
    const [scoringSystem, setScoringSystem] = useState('');
    const [neededObjects, setNeededObjects] = useState([]);
    const [effortLevel, setEffortLevel] = useState('');
    const [difficultyLevel, setDifficultyLevel] = useState('');
    const [goalSetting, setGoalSetting] = useState('');
    const [estimatedScore, setEstimatedScore] = useState('');
    const [completedTaskNames, setCompletedTaskNames] = useState(getCompletedTaskNames());
    const [canFinish, setCanFinish] = useState(false);
    const [showInstruction, setShowInstruction] = useState(false);
    const [showExamples, setShowExamples] = useState(false);

    const navigate = useNavigate();
    const trackButtonClick = useButtonTracking();
    const currentLang = sessionStorage.getItem('language');

    const handleStartCreate = () => {
        trackButtonClick('taskStartCreate');
        setIsCreating(true);
    };

    const handleObjectClick = (object) => {
        if (isCreating) {
            setNeededObjects(prev => {
                const existingObject = prev.find(obj => obj.id === object.id);
                if (existingObject) {
                    return prev.map(obj =>
                        obj.id === object.id ? { ...obj, count: Math.min(obj.count + 1, object.count) } : obj
                    );
                } else {
                    return [...prev, { ...object, count: 1 }];
                }
            });
        }
    };

    const handleNeededObjectChange = (id, newCount) => {
        setNeededObjects(prev => prev.map(obj => {
            if (obj.id === id) {
                const originalObject = objects.find(origObj => origObj.id === id);
                const validCount = Math.min(Math.max(1, parseInt(newCount) || 0), originalObject.count);
                return { ...obj, count: validCount };
            }
            return obj;
        }));
    };

    const handleDeleteNeededObject = (id) => {
        setNeededObjects(prev => prev.filter(obj => obj.id !== id));
    };

    useEffect(() => {
        const currentLang = sessionStorage.getItem('language');
        if (currentLang) {
            i18n.changeLanguage(currentLang);
        }
    }, [i18n]);


    useEffect(() => {
        let taskNameOK = false;
        let taskDescriptionOK = false;
        if (currentLang === 'en') {
            // English task name can have up to 6 words, and must be at least 1 word
            taskNameOK = taskName.split(' ').length <= 6 && taskName.length > 0;
            // English task description must be at least 6 words
            taskDescriptionOK = taskDescription.split(' ').length >= 6;
        } else {
            // Chinese task name can have up to 10 characters, and must be at least 1 character
            taskNameOK = taskName.length <= 10 && taskName.length > 0;
            // Chinese task description must be at least 6 characters
            taskDescriptionOK = taskDescription.length >= 6;
        }
        setCanFinish(
            taskNameOK && taskDescriptionOK &&
            scoringSystem.length > 0 &&
            detailedSetting.length > 0 && goalSetting.length > 0 && neededObjects.length > 0 &&
            effortLevel.length > 0 && difficultyLevel.length > 0 &&
            estimatedScore.length > 0 && !completedTaskNames.includes(taskName));
    }, [taskName, taskDescription, scoringSystem, detailedSetting, goalSetting, neededObjects, effortLevel, difficultyLevel, estimatedScore, completedTaskNames]);


    const handleFinish = () => {

        if (completedTaskNames.includes(taskName)) {
            alert(t('duplicateTaskNameError'));
            return;
        }

        let taskNameOK = false;
        let taskDescriptionOK = false;
        if (currentLang === 'en') {
            taskNameOK = taskName.split(' ').length <= 6 && taskName.length > 0;
            taskDescriptionOK = taskDescription.split(' ').length >= 6;
        } else {
            taskNameOK = taskName.length <= 10 && taskName.length > 0;
            taskDescriptionOK = taskDescription.length >= 6;
        }

        if (!taskNameOK) {
            alert(t('taskNameLengthError'));
            return;
        }
        if (!taskDescriptionOK) {
            alert(t('taskDescriptionLengthError'));
            return;
        }

        if (neededObjects.length === 0) {
            alert(t('noNeededObjectsError'));
            return;
        }

        if (goalSetting.length === 0) {
            alert(t('goalSettingLengthError'));
            return;
        }

        if (detailedSetting.length === 0) {
            alert(t('detailedSettingLengthError'));
            return;
        }

        if (scoringSystem.length === 0) {
            alert(t('scoringSystemLengthError'));
            return;
        }

        if (estimatedScore.length === 0) {
            alert(t('estimatedScoreLengthError'));
            return;
        }

        if (!canFinish) return;

        trackButtonClick(`taskFinish-${taskCount + 1}`);

        const neededObjectsData = neededObjects.map(obj => ({
            id: obj.id,
            name: obj.name,
            count: obj.count
        }));

        const taskInputs = {
            taskName,
            taskDescription,
            detailedSetting,
            goalSetting,
            scoringSystem,
            neededObjectsData,
            effortLevel,
            difficultyLevel,
            estimatedScore
        };

        saveTaskInputs(taskInputs);
        saveCompletedTaskName(taskName);

        setTaskCount(prev => prev + 1);
        setCompletedTaskNames(prev => [...prev, taskName]);
        setTaskName('');
        setTaskDescription('');
        setDetailedSetting('');
        setGoalSetting('');
        setScoringSystem('');
        setNeededObjects([]);
        setEffortLevel('');
        setDifficultyLevel('');
        setEstimatedScore('');
        setIsCreating(false);
    };

    const handleExit = () => {
        trackButtonClick('taskExit');
        navigate('/evaluation');
    };



    const getInstructionText = () => {
        if (taskCount === 0) {
            return t('initialInstruction');
        } else {
            return t('tasksCompleted', { count: taskCount });
        }
    };

    useEffect(() => {
        const selectedObjects = getObjects();
        setObjects(selectedObjects);
    }, []);

    return (
        <Container>
            <ContentContainer>
                <SideButtonContainer>
                    <SideButton onClick={() => setShowExamples(true)}>
                        {t('viewExamples')}
                    </SideButton>
                    <SideButton onClick={() => setShowInstruction(true)}>
                        {t('viewInstructions')}
                    </SideButton>
                </SideButtonContainer>

                {showInstruction && (
                    <Modal onClick={() => setShowInstruction(false)}>
                        <ModalContent onClick={(e) => e.stopPropagation()}>
                            <h2>{t('instructionsTitle')}</h2>
                            <StyledMarkdown remarkPlugins={[remarkGfm]}>
                                {t('experiment_detail_page3')}
                            </StyledMarkdown>
                            <Button onClick={() => setShowInstruction(false)}>
                                {t('close')}
                            </Button>
                        </ModalContent>
                    </Modal>
                )}

                {showExamples && (
                    <ImageModal onClick={() => setShowExamples(false)}>
                        <img src={`/taskintro_images/modal_examples${currentLang === 'en' ? '' : '_zh'}.jpeg`} alt="Task Examples" />
                    </ImageModal>
                )}


                <TaskContainer>
                    <LeftSection>
                        <IconGrid>
                            {objects.map(object => (
                                <IconItem
                                    key={object.id}
                                    $clickable={isCreating}
                                    onClick={() => handleObjectClick(object)}
                                >
                                    <IconImage src={`/images/${object.icon}`} alt={object.name} />
                                    <IconText>{object.name} × {object.count}</IconText>
                                </IconItem>
                            ))}
                        </IconGrid>
                    </LeftSection>



                    <RightSection>
                        {!isCreating ? (
                            <>
                                <Instruction>
                                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                        {getInstructionText()}
                                    </ReactMarkdown>
                                </Instruction>
                                {taskCount === 0 ? (
                                    <StartButton onClick={handleStartCreate}>
                                        {t('startCreateTask')}
                                    </StartButton>
                                ) : (
                                    <StartButton onClick={handleStartCreate}>
                                        {t('continueCreateTask')}
                                    </StartButton>
                                )}
                                {taskCount >= 3 && (
                                    <ExitButton onClick={handleExit}>{t('exit')}</ExitButton>
                                )}
                            </>
                        ) : (
                            <RightSectionContainer>
                                <InputLabel>
                                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                        {t('taskName')}
                                    </ReactMarkdown>
                                </InputLabel>
                                <InputField
                                    value={taskName}
                                    onChange={(e) => setTaskName(e.target.value)}
                                    placeholder={t('taskNamePlaceholder')}
                                />

                                <InputLabel>
                                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                        {t('neededObjects')}
                                    </ReactMarkdown>
                                </InputLabel>
                                <NeededObjectsGrid $isEmpty={neededObjects.length === 0}>
                                    {neededObjects.map(obj => (
                                        <NeededObjectItem key={obj.id}>
                                            <DeleteButton onClick={() => handleDeleteNeededObject(obj.id)}> × </DeleteButton>
                                            <NeededObjectContent>
                                                <NeededObjectImage src={`/images/${obj.icon}`} alt={obj.name} />
                                                <NeededObjectCountInput
                                                    type="number"
                                                    value={obj.count}
                                                    onChange={(e) => handleNeededObjectChange(obj.id, e.target.value)}
                                                    min="1"
                                                    max={objects.find(origObj => origObj.id === obj.id).count}
                                                />
                                            </NeededObjectContent>
                                        </NeededObjectItem>
                                    ))}
                                </NeededObjectsGrid>


                                <InputLabel>
                                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                        {t('taskDescription')}
                                    </ReactMarkdown>
                                </InputLabel>
                                <TextArea
                                    value={taskDescription}
                                    onChange={(e) => setTaskDescription(e.target.value)}
                                    placeholder={t('taskDescriptionPlaceholder')}
                                />

                                <InputLabel>
                                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                        {t('detailedSetting')}
                                    </ReactMarkdown>
                                </InputLabel>
                                <TextArea
                                    value={detailedSetting}
                                    onChange={(e) => setDetailedSetting(e.target.value)}
                                    placeholder={t('detailedSettingPlaceholder')}
                                />

                                <InputLabel>
                                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                        {t('goalSetting')}
                                    </ReactMarkdown>
                                </InputLabel>
                                <TextArea
                                    value={goalSetting}
                                    onChange={(e) => setGoalSetting(e.target.value)}
                                    placeholder={t('goalSettingPlaceholder')}
                                />

                                <InputLabel>
                                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                        {t('scoringSystem')}
                                    </ReactMarkdown>
                                </InputLabel>
                                <TextArea
                                    value={scoringSystem}
                                    onChange={(e) => setScoringSystem(e.target.value)}
                                    placeholder={t('scoringSystemPlaceholder')}
                                />

                                <InputLabel>
                                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                        {t('effortLevel')}
                                    </ReactMarkdown>
                                </InputLabel>
                                <Select
                                    value={effortLevel}
                                    onChange={(e) => setEffortLevel(e.target.value)}
                                >
                                    <option value="">{t('selectOption')}</option>
                                    <option value="1">{t('effort1')}</option>
                                    <option value="2">{t('effort2')}</option>
                                    <option value="3">{t('effort3')}</option>
                                    <option value="4">{t('effort4')}</option>
                                    <option value="5">{t('effort5')}</option>
                                    <option value="6">{t('effort6')}</option>
                                    <option value="7">{t('effort7')}</option>
                                </Select>
                                <InputLabel>
                                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                        {t('difficultyLevel')}
                                    </ReactMarkdown>
                                </InputLabel>
                                <Select
                                    value={difficultyLevel}
                                    onChange={(e) => setDifficultyLevel(e.target.value)}
                                >
                                    <option value="">{t('selectOption')}</option>
                                    <option value="1">{t('difficulty1')}</option>
                                    <option value="2">{t('difficulty2')}</option>
                                    <option value="3">{t('difficulty3')}</option>
                                    <option value="4">{t('difficulty4')}</option>
                                    <option value="5">{t('difficulty5')}</option>
                                    <option value="6">{t('difficulty6')}</option>
                                    <option value="7">{t('difficulty7')}</option>
                                </Select>
                                <InputLabel>
                                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                        {t('scoreEstimation')}
                                    </ReactMarkdown>
                                </InputLabel>
                                <ScoreInputContainer>
                                    <ScoreInput
                                        type="number"
                                        value={estimatedScore}
                                        onChange={(e) => setEstimatedScore(e.target.value)}
                                        placeholder={t('estimatedScorePlaceholder')}
                                    />
                                    {/* <ScoreInput
                                        type="number"
                                        value={fullScore}
                                        onChange={(e) => setFullScore(e.target.value)}
                                        placeholder={t('fullScorePlaceholder')}
                                    /> */}
                                </ScoreInputContainer>

                                <ButtonContainer>
                                    <AgreeButton
                                        onClick={handleFinish}
                                        style={{ opacity: canFinish ? 1 : 0.5, cursor: canFinish ? 'pointer' : 'not-allowed' }}
                                    >
                                        {t('finishTask')}
                                    </AgreeButton>
                                </ButtonContainer>
                            </RightSectionContainer>
                        )}
                    </RightSection>
                </TaskContainer>
            </ContentContainer>
        </Container>
    );
};

export default Task;