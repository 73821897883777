import axios from 'axios';

const API_BASE_URL = 'https://value-cloudflare-worker.songjiajun.workers.dev'; // Adjust this to match your FastAPI server address

export const sendSessionData = async (data) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/api/session-data`, { data });
        return response.data;
    } catch (error) {
        console.error('Error sending session data:', error);
        throw error;
    }
};