import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Radar } from 'react-chartjs-2';
import { QuestionText } from '../globalStyle';

const SectionContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

const RadarChartContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 15vw;
`;

const SliderContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 40%;
  height: 100%;
`;

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 100%;
`;


const SliderRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  width: 100%;
`;

const DimensionLabel = styled.span`
  width: 50%;
  margin-right: 10px;
  padding: 5px;
  font-size: 0.9rem;

  @media (max-height: 600px) {
    width: 60%;
  }
`;

const DimensionSlider = styled.input`
  flex-grow: 1;
  width: 50%;

  @media (max-height: 600px) {
    width: 40%;
  }
`;


const UserInputLabel = styled.input`
  width: 28%;
  margin-right: 2%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 0.8rem;

  @media (max-height: 600px) {
    font-size: 0.4rem;
  }
`;

const RadarChartSection = ({ radarData, setRadarData, title }) => {
    const [userLabel, setUserLabel] = useState('');
    const [hasAddedLabel, setHasAddedLabel] = useState(false);
    const { t, i18n } = useTranslation();
    const currentLang = sessionStorage.getItem('language');

    const handleRadarChange = (index, value) => {
        const newData = [...radarData.datasets[0].data];
        newData[index] = parseInt(value);
        const newRadarData = {
            ...radarData,
            datasets: [{ ...radarData.datasets[0], data: newData }]
        };
        setRadarData(newRadarData);
    };

    const handleUserLabelChange = (e) => {
        const newLabel = e.target.value;
        setUserLabel(newLabel);

        const newLabels = [...radarData.labels];
        const newData = [...radarData.datasets[0].data];

        if (newLabel.trim() !== '') {
            if (!hasAddedLabel) {
                newLabels.push(newLabel);
                newData.push(0); // Add a new data point
                setHasAddedLabel(true);
            } else {
                newLabels[newLabels.length - 1] = newLabel;
            }
        } else if (hasAddedLabel) {
            newLabels.pop();
            newData.pop();
            setHasAddedLabel(false);
        }

        const newRadarData = {
            ...radarData,
            labels: newLabels,
            datasets: [{ ...radarData.datasets[0], data: newData }]
        };
        setRadarData(newRadarData);
    };

    return (
        <SectionContainer>
            <QuestionText>{title}</QuestionText>
            <RadarChartContainer>
                <SliderContainer>
                    {radarData.labels.slice(0, hasAddedLabel ? -1 : undefined).map((dim, index) => (
                        <SliderRow key={index}>
                            <DimensionLabel>{dim}</DimensionLabel>
                            <DimensionSlider
                                type="range"
                                min="0"
                                max="100"
                                value={radarData.datasets[0].data[index]}
                                onChange={(e) => handleRadarChange(index, e.target.value)}
                            />
                        </SliderRow>
                    ))}
                    <SliderRow>
                        <UserInputLabel
                            value={userLabel}
                            onChange={handleUserLabelChange}
                            placeholder={t('others')}
                        />
                        {hasAddedLabel && (
                            <DimensionSlider
                                type="range"
                                min="0"
                                max="100"
                                value={radarData.datasets[0].data[radarData.datasets[0].data.length - 1]}
                                onChange={(e) => handleRadarChange(radarData.labels.length - 1, e.target.value)}
                            />
                        )}
                    </SliderRow>
                </SliderContainer>
                <ChartWrapper>
                    <Radar
                        data={radarData}
                        options={{
                            scales: {
                                r: {
                                    min: 0,
                                    max: 100,
                                    beginAtZero: true,
                                    ticks: {
                                        stepSize: 25,
                                        showLabelBackdrop: false,
                                        font: { size: 8 }
                                    },
                                    pointLabels: { font: { size: 8 } },
                                    grid: { circular: true, lineWidth: 1 },
                                    angleLines: { lineWidth: 1 }
                                }
                            },
                            plugins: { legend: { display: false } },
                            maintainAspectRatio: false,
                            responsive: true
                        }}
                    />
                </ChartWrapper>
            </RadarChartContainer>
        </SectionContainer>
    );
};

export default RadarChartSection;