import ReactMarkdown from 'react-markdown';
import { styled, createGlobalStyle } from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    overflow-y: auto;
    background-color: #f0f4f8;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 90vh;
    padding-bottom: 5vh;
    width: 90vw;

    @media (max-width: 768px) {
        max-height: 100vh;
        padding-bottom: 0vh;
    }
`;

export const Title = styled.h1`
    font-size: clamp(1.5rem, 4vw, 2rem);
    color: #333;
    margin-bottom: 20px;
`;

export const Subtitle = styled.p`
    font-size: clamp(0.875rem, 2vw, 1.25rem);
    margin-bottom: 30px;
`;

// Button

export const ButtonContainer = styled.div`
    display: flex;
    gap: 20px;
`;

export const Button = styled.button`
    background-color: #4A90E2;
    color: white;
    font-size: 1rem;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100px;
    &:hover {
        background-color: #357ABD;
    }
`;

// ##############################
// Consent
// ##############################



export const AgreeButton = styled.button`
  background-color: ${props => props.disabled ? '#ccc' : '#4A90E2'};
  color: white;
  font-size: clamp(0.8rem, 2vw, 1rem);
  padding: clamp(8px, 2vw, 10px) clamp(10px, 3vw, 20px);
  border: none;
  border-radius: 5px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: background-color 0.3s, transform 0.2s;
  width: clamp(80px, 20vw, 120px);

  &:hover {
    background-color: ${props => props.disabled ? '#ccc' : '#357ABD'};
    transform: ${props => props.disabled ? 'none' : 'scale(1.05)'};
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
    padding: 8px 15px;
    width: 80px;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
    padding: 6px 12px;
    width: 20vw;
  }
`;

// ##############################
// Welcome
// ##############################


export const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 700px; // Adjust this value to control the maximum width
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
`;



// Markdown
export const StyledMarkdown = styled(ReactMarkdown)`
    font-size: clamp(0.8rem, 2vw, 1rem);
    line-height: 1.5;

    h1, h2, h3, h4, h5, h6 {
      margin-top: 0.5em;
      margin-bottom: 0.2em;
    }

    p {
      margin-bottom: 1em;
    }

    ul, ol {
      margin-bottom: 1em;
      padding-left: 2em;
    }

    li {
      margin-bottom: 0.5em;
    }

    strong {
      font-weight: bold;
    }

    em {
      font-style: italic;
    }

`;

// ##############################
// Survey
// ##############################

export const SurveyContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 20px;
  max-height: 30%;
  max-width: 90%;
  min-width: 60%;
  margin-bottom: 20px;

`;

export const MatrixContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  overflow: hidden;
`;

export const MatrixScrollContainer = styled.div`
  overflow-y: auto;
  flex: 1;
  max-height: 75vh;
`;

export const MatrixHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1vh;
  margin-left: 58%;
  position: sticky;
  top: 0;
  z-index: 1;

`;

export const MatrixHeaderItem = styled.div`
  text-align: center;
  font-size: 0.8rem;
  font-weight: bold;

  @media (max-width: 1200px) {
    font-size: 0.6rem;
  }

  @media (max-width: 900px) {
    font-size: 0.6rem;
  }

`;

export const MatrixRow = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  &:nth-child(even) {
    background-color: #f0f4f8;
  }

  &:nth-child(odd) {
    background-color: #e1e7ed;
  }

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

export const MatrixQuestion = styled.div`
  flex: 1;
  font-size: clamp(0.8rem, 2vw, 1rem);
  color: #333;
  width: 60%;
  // padding-right: 10px;


  @media (max-width: 480px) {
    font-size: 0.5rem;
  }
`;

export const MatrixChoices = styled.div`
  display: flex;
  justify-content: space-between;
  width: 40%;
`;

export const MatrixChoice = styled.input`
  margin: 0 10px;
  width: 18px;
  height: 18px;

  @media (max-width: 768px) {
    width: 10px;
    height: 10px;
    margin: 0 3px;
  }
`;


export const QuestionContainer = styled.div`
  margin-bottom: 30px;
`;

export const QuestionText = styled.p`
  font-size: clamp(0.8rem, 2vw, 1rem);
  margin-bottom: clamp(10px, 2vw, 15px);
  color: #333;
  font-weight: bold;
`;


export const ScaleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ScaleLabels = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
`;

export const ScaleLabel = styled.span`
  font-size: 0.8rem;
  color: #666;

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;