export const quizQuestions = {
    "zh": [{
        id: 'q1',
        text: '1：实验中我需要根据场景中的物品想出任务用来打发时间。',
        correctAnswer: true
    },
    {
        id: 'q2',
        text: '2：我想象出的任务可以包含场景中不存在的物品。',
        correctAnswer: false
    },
    {
        id: 'q3',
        text: '3：每个任务用到的物品数目可以超出场景中物品的真实数目。',
        correctAnswer: false
    },
    {
        id: 'q4',
        text: '4：即使场景中没有其他人，我也可以报告多人参与的任务。',
        correctAnswer: false
    }],
    "en": [{
        id: 'q1',
        text: '1. In the experiment, I had to come up with tasks based on the objects in the scene to pass the time.',
        correctAnswer: true
    },
    {
        id: 'q2',
        text: '2. The task I have imagined can contain items that do not exist in the scene.',
        correctAnswer: false
    },
    {
        id: 'q3',
        text: '3. The number of items used for each task can exceed the true number of items in the scene.',
        correctAnswer: false
    },
    {
        id: 'q4',
        text: '4. I can report on multiplayer tasks even if there is no one else in the scene.',
        correctAnswer: false
    }]
};