const SETTINGS = {
    DUMMY_FEW: 'dummy_few',
    DUMMY_MANY: 'dummy_many',
    HUMAN_FEW: 'human_few',
    // HUMAN_MANY: 'human_many'
};

export const assignUserSetting = () => {
    const settings = Object.values(SETTINGS);
    const randomSetting = settings[Math.floor(Math.random() * settings.length)];
    sessionStorage.setItem('userSetting', randomSetting);
    return randomSetting;
};

export const getUserSetting = () => {
    let setting = sessionStorage.getItem('userSetting');
    if (!setting) {
        setting = assignUserSetting();
    }
    return setting;
};

export const isHumanSetting = (setting) => {
    return setting === SETTINGS.HUMAN_FEW || setting === SETTINGS.HUMAN_MANY;
};

export const isManySetting = (setting) => {
    return setting === SETTINGS.DUMMY_MANY || setting === SETTINGS.HUMAN_MANY;
};