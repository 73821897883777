import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Container, Title, Subtitle, Button, ContentContainer, StyledMarkdown } from '../globalStyle';
import { useButtonTracking } from '../hooks/useButtonTracking';

const ExperimentIntroContainer = styled.div`
    text-align: justify;
    width: 50%;
    max-height: 80%;
    overflow-y: auto;
    margin-bottom: 5vh;
    font-size: clamp(0.6rem, 1rem, 1.5rem);

    
    @media (max-height: 768px) {
        font-size: 0.6rem;
    }
    @media (max-height: 480px) {
        font-size: 0.4rem;
    }
`;

const ImageContainer = styled.div`
    width: 100%;
    margin-top: 2vh;
    text-align: center;
`;

const StyledImage = styled.img`
    width: 90%;
    height: auto;
    margin-bottom: 2vh;
`;


const NavigationButtons = styled.div`
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin-top: 2vh;
`;


function SurveyCompleted() {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const trackButtonClick = useButtonTracking();
    const [currentPage, setCurrentPage] = useState(1);

    const handleAgree = () => {
        trackButtonClick('surveyCompletedProceed');
        navigate('/quiz');
    };

    const handleNext = () => {
        setCurrentPage(prev => Math.min(prev + 1, 3));
    };

    const handlePrev = () => {
        setCurrentPage(prev => Math.max(prev - 1, 1));
    };

    const currentLang = sessionStorage.getItem('language');
    useEffect(() => {
        const currentLang = sessionStorage.getItem('language');
        if (currentLang) {
            i18n.changeLanguage(currentLang);
        }
    }, [i18n]);


    return (
        <Container>
            <ContentContainer>
                <Title>{t('experiment')}</Title>
                <ExperimentIntroContainer>
                    <StyledMarkdown>
                        {t(`experiment_detail_page${currentPage}`)}
                    </StyledMarkdown>

                    {currentPage === 1 && (
                        <ImageContainer>
                            <StyledImage src={`/taskintro_images/${currentLang === 'en' ? 'interface' : 'interface_zh'}.${currentLang === 'en' ? 'png' : 'jpeg'}`} alt="Experiment interface" />
                        </ImageContainer>
                    )}

                    {currentPage === 2 && (
                        <ImageContainer>
                            <StyledImage src={`/taskintro_images/example1${currentLang === 'en' ? '' : '_zh'}.jpeg`} alt="Example 1" />
                            <StyledImage src={`/taskintro_images/example2${currentLang === 'en' ? '' : '_zh'}.jpeg`} alt="Example 2" />
                        </ImageContainer>
                    )}
                </ExperimentIntroContainer>

                <NavigationButtons>
                    {currentPage > 1 && (
                        <Button onClick={handlePrev}>
                            {t('previous')}
                        </Button>
                    )}
                    {currentPage < 3 ? (
                        <Button onClick={handleNext}>
                            {t('next')}
                        </Button>
                    ) : (
                        <Button onClick={handleAgree}>
                            {t('proceed')}
                        </Button>
                    )}
                </NavigationButtons>
            </ContentContainer>
        </Container>
    );
}

export default SurveyCompleted;