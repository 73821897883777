export const objectsData_en = {
    few: [
        { 'id': 9, 'name': 'Broom', 'icon': 'Broom.png', 'count': 1 },
        { 'id': 10, 'name': 'Clock', 'icon': 'Clock.png', 'count': 1 },
        { 'id': 11, 'name': 'Clothes tree', 'icon': 'Clothes_tree.png', 'count': 1 },
        { 'id': 12, 'name': 'Trash can', 'icon': 'Trash_can.png', 'count': 1 },
        { 'id': 13, 'name': 'Kettle', 'icon': 'Kettle.png', 'count': 1 },
        { 'id': 14, 'name': 'Lamp', 'icon': 'Lamp.png', 'count': 1 },
        { 'id': 15, 'name': 'Newspaper', 'icon': 'Newspaper.png', 'count': 1 },
        { 'id': 16, 'name': 'Notebook', 'icon': 'Notebook.png', 'count': 1 },
        { 'id': 17, 'name': 'Poster', 'icon': 'Poster.png', 'count': 1 },
        { 'id': 18, 'name': 'Rug', 'icon': 'Rug.png', 'count': 1 },
        { 'id': 19, 'name': 'Sofa', 'icon': 'Sofa.png', 'count': 1 },
        { 'id': 20, 'name': 'Tissue', 'icon': 'Tissue.png', 'count': 1 },
        { 'id': 21, 'name': 'Tray', 'icon': 'Tray.png', 'count': 1 },
        { 'id': 22, 'name': 'TV', 'icon': 'TV.png', 'count': 1 },
        { 'id': 23, 'name': 'Waste paper ball', 'icon': 'Paper_ball.png', 'count': 1 },
        { 'id': 24, 'name': 'Clothes hangers', 'icon': 'Clothes_hanger.png', 'count': 2 },
        { 'id': 25, 'name': 'Desk', 'icon': 'Desk.png', 'count': 2 },
        { 'id': 26, 'name': 'Paintings', 'icon': 'Paintings.png', 'count': 2 },
        { 'id': 27, 'name': 'Pen', 'icon': 'Pen.png', 'count': 1 },
        { 'id': 28, 'name': 'Pillow', 'icon': 'Pillow.png', 'count': 2 },
        { 'id': 29, 'name': 'Potted plants', 'icon': 'Potted_plants.png', 'count': 1 },
        { 'id': 30, 'name': 'Bowl', 'icon': 'Bowl.png', 'count': 4 },
        { 'id': 31, 'name': 'Chair', 'icon': 'Chair.png', 'count': 4 },
        { 'id': 32, 'name': 'Cup', 'icon': 'Cup.png', 'count': 2 },
        { 'id': 33, 'name': 'Book', 'icon': 'Book.png', 'count': 5 },],
    many: [
        { 'id': 2, 'name': 'Basketball', 'icon': 'Basketball.png', 'count': 1 },
        { 'id': 3, 'name': 'Chess board&pieces', 'icon': 'Chess_board_&_pieces.png', 'count': 1 },
        { 'id': 4, 'name': 'Dice', 'icon': 'Dice_block.png', 'count': 1 },
        { 'id': 5, 'name': 'Kalimba', 'icon': 'Kalimba.png', 'count': 1 },
        { 'id': 6, 'name': 'Tennis ball', 'icon': 'Tennis_ball.png', 'count': 1 },
        { 'id': 7, 'name': 'Treadmill', 'icon': 'Treadmill.png', 'count': 1 },
        { 'id': 8, 'name': 'Building block', 'icon': 'Building_block.png', 'count': 5 },
        { 'id': 9, 'name': 'Broom', 'icon': 'Broom.png', 'count': 1 },
        { 'id': 10, 'name': 'Clock', 'icon': 'Clock.png', 'count': 1 },
        { 'id': 11, 'name': 'Clothes tree', 'icon': 'Clothes_tree.png', 'count': 1 },
        { 'id': 12, 'name': 'Trash can', 'icon': 'Trash_can.png', 'count': 1 },
        { 'id': 13, 'name': 'Kettle', 'icon': 'Kettle.png', 'count': 1 },
        { 'id': 14, 'name': 'Lamp', 'icon': 'Lamp.png', 'count': 1 },
        { 'id': 15, 'name': 'Newspaper', 'icon': 'Newspaper.png', 'count': 1 },
        { 'id': 16, 'name': 'Notebook', 'icon': 'Notebook.png', 'count': 1 },
        { 'id': 17, 'name': 'Poster', 'icon': 'Poster.png', 'count': 1 },
        { 'id': 18, 'name': 'Rug', 'icon': 'Rug.png', 'count': 1 },
        { 'id': 19, 'name': 'Sofa', 'icon': 'Sofa.png', 'count': 1 },
        { 'id': 20, 'name': 'Tissue', 'icon': 'Tissue.png', 'count': 1 },
        { 'id': 21, 'name': 'Tray', 'icon': 'Tray.png', 'count': 1 },
        { 'id': 22, 'name': 'TV', 'icon': 'TV.png', 'count': 1 },
        { 'id': 23, 'name': 'Waste paper ball', 'icon': 'Paper_ball.png', 'count': 1 },
        { 'id': 24, 'name': 'Clothes hangers', 'icon': 'Clothes_hanger.png', 'count': 2 },
        { 'id': 25, 'name': 'Desk', 'icon': 'Desk.png', 'count': 2 },
        { 'id': 26, 'name': 'Paintings', 'icon': 'Paintings.png', 'count': 2 },
        { 'id': 27, 'name': 'Pen', 'icon': 'Pen.png', 'count': 1 },
        { 'id': 28, 'name': 'Pillow', 'icon': 'Pillow.png', 'count': 2 },
        { 'id': 29, 'name': 'Potted plants', 'icon': 'Potted_plants.png', 'count': 1 },
        { 'id': 30, 'name': 'Bowl', 'icon': 'Bowl.png', 'count': 4 },
        { 'id': 31, 'name': 'Chair', 'icon': 'Chair.png', 'count': 4 },
        { 'id': 32, 'name': 'Cup', 'icon': 'Cup.png', 'count': 2 },
        { 'id': 33, 'name': 'Book', 'icon': 'Book.png', 'count': 5 },],
    human: [
        { 'id': 1, 'name': 'Other people', 'icon': 'Other_people.png', 'count': 1 },
    ],
    dummy: [
        { 'id': 0, 'name': 'Dummy', 'icon': 'Dummy.png', 'count': 1 },
    ]
};

export const objectsData_zh = {
    few: [
        { 'id': 9, 'name': '扫把', 'icon': 'Broom.png', 'count': 1 },
        { 'id': 10, 'name': '闹钟', 'icon': 'Clock.png', 'count': 1 },
        { 'id': 11, 'name': '落地衣架', 'icon': 'Clothes_tree.png', 'count': 1 },
        { 'id': 12, 'name': '空垃圾桶', 'icon': 'Trash_can.png', 'count': 1 },
        { 'id': 13, 'name': '水壶', 'icon': 'Kettle.png', 'count': 1 },
        { 'id': 14, 'name': '台灯', 'icon': 'Lamp.png', 'count': 1 },
        { 'id': 15, 'name': '报纸', 'icon': 'Newspaper.png', 'count': 1 },
        { 'id': 16, 'name': '作业本', 'icon': 'Notebook.png', 'count': 1 },
        { 'id': 17, 'name': '海报', 'icon': 'Poster.png', 'count': 1 },
        { 'id': 18, 'name': '地毯', 'icon': 'Rug.png', 'count': 1 },
        { 'id': 19, 'name': '沙发', 'icon': 'Sofa.png', 'count': 1 },
        { 'id': 20, 'name': '纸巾', 'icon': 'Tissue.png', 'count': 1 },
        { 'id': 21, 'name': '托盘', 'icon': 'Tray.png', 'count': 1 },
        { 'id': 22, 'name': '电视', 'icon': 'TV.png', 'count': 1 },
        { 'id': 23, 'name': '废纸球', 'icon': 'Paper_ball.png', 'count': 1 },
        { 'id': 24, 'name': '衣架', 'icon': 'Clothes_hanger.png', 'count': 2 },
        { 'id': 25, 'name': '桌子', 'icon': 'Desk.png', 'count': 2 },
        { 'id': 26, 'name': '装饰画', 'icon': 'Paintings.png', 'count': 2 },
        { 'id': 27, 'name': '签字笔', 'icon': 'Pen.png', 'count': 1 },
        { 'id': 28, 'name': '抱枕', 'icon': 'Pillow.png', 'count': 2 },
        { 'id': 29, 'name': '盆栽', 'icon': 'Potted_plants.png', 'count': 1 },
        { 'id': 30, 'name': '碗', 'icon': 'Bowl.png', 'count': 4 },
        { 'id': 31, 'name': '椅子', 'icon': 'Chair.png', 'count': 4 },
        { 'id': 32, 'name': '杯子', 'icon': 'Cup.png', 'count': 2 },
        { 'id': 33, 'name': '书', 'icon': 'Book.png', 'count': 5 },],
    many: [
        { 'id': 2, 'name': '篮球', 'icon': 'Basketball.png', 'count': 1 },
        { 'id': 3, 'name': '棋盘与棋子', 'icon': 'Chess_board_&_pieces.png', 'count': 1 },
        { 'id': 4, 'name': '骰子', 'icon': 'Dice_block.png', 'count': 1 },
        { 'id': 5, 'name': '拇指琴', 'icon': 'Kalimba.png', 'count': 1 },
        { 'id': 6, 'name': '网球', 'icon': 'Tennis_ball.png', 'count': 1 },
        { 'id': 7, 'name': '跑步机', 'icon': 'Treadmill.png', 'count': 1 },
        { 'id': 8, 'name': '积木块', 'icon': 'Building_block.png', 'count': 5 },
        { 'id': 9, 'name': '扫把', 'icon': 'Broom.png', 'count': 1 },
        { 'id': 10, 'name': '闹钟', 'icon': 'Clock.png', 'count': 1 },
        { 'id': 11, 'name': '落地衣架', 'icon': 'Clothes_tree.png', 'count': 1 },
        { 'id': 12, 'name': '空垃圾桶', 'icon': 'Trash_can.png', 'count': 1 },
        { 'id': 13, 'name': '水壶', 'icon': 'Kettle.png', 'count': 1 },
        { 'id': 14, 'name': '台灯', 'icon': 'Lamp.png', 'count': 1 },
        { 'id': 15, 'name': '报纸', 'icon': 'Newspaper.png', 'count': 1 },
        { 'id': 16, 'name': '作业本', 'icon': 'Notebook.png', 'count': 1 },
        { 'id': 17, 'name': '海报', 'icon': 'Poster.png', 'count': 1 },
        { 'id': 18, 'name': '地毯', 'icon': 'Rug.png', 'count': 1 },
        { 'id': 19, 'name': '沙发', 'icon': 'Sofa.png', 'count': 1 },
        { 'id': 20, 'name': '纸巾', 'icon': 'Tissue.png', 'count': 1 },
        { 'id': 21, 'name': '托盘', 'icon': 'Tray.png', 'count': 1 },
        { 'id': 22, 'name': '电视', 'icon': 'TV.png', 'count': 1 },
        { 'id': 23, 'name': '废纸球', 'icon': 'Paper_ball.png', 'count': 1 },
        { 'id': 24, 'name': '衣架', 'icon': 'Clothes_hanger.png', 'count': 2 },
        { 'id': 25, 'name': '桌子', 'icon': 'Desk.png', 'count': 2 },
        { 'id': 26, 'name': '装饰画', 'icon': 'Paintings.png', 'count': 2 },
        { 'id': 27, 'name': '签字笔', 'icon': 'Pen.png', 'count': 1 },
        { 'id': 28, 'name': '抱枕', 'icon': 'Pillow.png', 'count': 2 },
        { 'id': 29, 'name': '盆栽', 'icon': 'Potted_plants.png', 'count': 1 },
        { 'id': 30, 'name': '碗', 'icon': 'Bowl.png', 'count': 4 },
        { 'id': 31, 'name': '椅子', 'icon': 'Chair.png', 'count': 4 },
        { 'id': 32, 'name': '杯子', 'icon': 'Cup.png', 'count': 2 },
        { 'id': 33, 'name': '书', 'icon': 'Book.png', 'count': 5 },],
    human: [
        { 'id': 1, 'name': '其他人', 'icon': 'Other_people.png', 'count': 1 },
    ],
    dummy: [
        { 'id': 0, 'name': '人偶', 'icon': 'Dummy.png', 'count': 1 },
    ]
};