import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { surveyQuestions, surveyTitles } from '../locales/surveyQuestions';
import { getNextSurvey } from '../utils/surveyFlow';
import { saveSurveyAnswers, getSurveyAnswers } from '../utils/storage';
import { Container, Title, Subtitle, QuestionText, MatrixScrollContainer } from '../globalStyle';
import { SurveyContainer, ContentContainer, AgreeButton, MatrixContainer, MatrixHeader, MatrixHeaderItem, MatrixRow, MatrixQuestion, MatrixChoices, MatrixChoice } from '../globalStyle';
import { ScaleContainer, ScaleLabels, ScaleLabel, QuestionContainer } from '../globalStyle';
import SingleChoice from '../components/SingleChoice';
import { useButtonTracking } from '../hooks/useButtonTracking';

const SubtitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
    // color: #666;
    max-width: 60%;
    min-width: 40%;
    font-size: clamp(0.875rem, 2vw, 1.25rem);
`;

const Survey = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { surveyId } = useParams();
    const [answers, setAnswers] = useState({});
    const [allAnswered, setAllAnswered] = useState(false);

    const currentLang = sessionStorage.getItem('language');
    const survey = surveyQuestions[currentLang]?.[surveyId] || surveyQuestions.en?.[surveyId];
    const title = surveyTitles[currentLang]?.[surveyId] || surveyTitles.en?.[surveyId];

    const trackButtonClick = useButtonTracking();

    useEffect(() => {
        setAnswers({});
    }, [surveyId, currentLang]);

    useEffect(() => {
        setAllAnswered(Object.keys(answers).length === survey.questions.length);
    }, [answers, survey.questions]);

    const handleScaleChange = (questionId, value) => {
        setAnswers(prev => ({ ...prev, [questionId]: value }));
    };

    const handleMatrixSelect = (questionId, value) => {
        setAnswers(prev => ({ ...prev, [questionId]: value }));
    };

    const handleProceed = () => {
        if (allAnswered) {
            trackButtonClick(`surveyProceed-${surveyId}`);
            const testQuestion = survey.questions.find(q => q.isTestQuestion);
            if (testQuestion && answers[testQuestion.id] !== testQuestion.correctAnswer) {
                saveSurveyAnswers(surveyId, answers);
                navigate('/end');
            } else {
                saveSurveyAnswers(surveyId, answers);
                const nextSurveyId = getNextSurvey(surveyId);
                if (nextSurveyId) {
                    navigate(`/survey/${nextSurveyId}`);
                } else {
                    navigate('/survey-completed');
                }
            }
        }
    };

    const renderQuestion = (question) => {
        switch (question.type) {
            case 'scale':
                return (
                    <ScaleContainer>
                        <QuestionText>{question.text}</QuestionText>
                        <input
                            type="range"
                            min={question.min}
                            max={question.max}
                            step="0.5"
                            value={answers[question.id] || question.min}
                            onChange={(e) => handleScaleChange(question.id, e.target.value)}
                            style={{ width: '100%' }}
                        />
                        <ScaleLabels>
                            <ScaleLabel>{question.label1}</ScaleLabel>
                            <ScaleLabel>{question.label2}</ScaleLabel>
                            <ScaleLabel>{question.label3}</ScaleLabel>
                            <ScaleLabel>{question.label4}</ScaleLabel>
                            <ScaleLabel>{question.label5}</ScaleLabel>
                        </ScaleLabels>
                    </ScaleContainer>
                );
            case 'choice':
                return (
                    <SingleChoice
                        questionText={question.text}
                        choices={question.choices}
                        answers={answers}
                        setAnswers={setAnswers}
                        questionId={question.id}
                    />
                );
            default:
                return null;
        }
    };
    return (
        <Container>
            <ContentContainer>
                <SubtitleContainer>{title}</SubtitleContainer>
                <SurveyContainer>
                    {survey.type === 'matrix' ? (
                        <MatrixContainer>
                            <MatrixScrollContainer>
                                <MatrixHeader>
                                    {survey.scaleLabels.map((label, index) => (
                                        <MatrixHeaderItem key={index}>{label}</MatrixHeaderItem>
                                    ))}
                                </MatrixHeader>

                                {survey.questions.map(question => (
                                    <MatrixRow key={question.id}>
                                        <MatrixQuestion>{question.text}</MatrixQuestion>
                                        <MatrixChoices>
                                            {survey.scaleLabels.map((scaleLabel, index) => (
                                                <MatrixChoice
                                                    key={scaleLabel}
                                                    type="radio"
                                                    name={question.id}
                                                    checked={answers[question.id] === scaleLabel}
                                                    onChange={() => handleMatrixSelect(question.id, scaleLabel)}
                                                />
                                            ))}
                                        </MatrixChoices>
                                    </MatrixRow>
                                ))}
                            </MatrixScrollContainer>
                        </MatrixContainer>
                    ) : (
                        survey.questions.map(question => (
                            <QuestionContainer key={question.id}>
                                {renderQuestion(question)}
                            </QuestionContainer>
                        ))
                    )}
                </SurveyContainer>
                <AgreeButton disabled={!allAnswered} onClick={handleProceed}>
                    {t('proceed')}
                </AgreeButton>
            </ContentContainer>
        </Container>
    );
};

export default Survey;