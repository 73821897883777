import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import styled from 'styled-components';
import { Container, Title, Subtitle, Button } from '../globalStyle';

const SurveyIntroContainer = styled.div`
    font-size: clamp(0.9rem, 5vw, 1.5rem);
    text-align: justify;
    line-height: 1.5;
    width: 80%;

    @media (min-width: 768px) {
        width: 60%;
    }

    @media (min-width: 1024px) {
        width: 40%;
    }

    strong {
        font-weight: bold;
    }

    ul, ol {
        margin-left: 20px;
        margin-bottom: 10px;
    }

    li {
        margin-bottom: 5px;
    }

    @media (max-width: 480px) {
        ul, ol {
            margin-left: 15px;
        }

        li {
            margin-bottom: 3px;
        }
    }
`;

function SurveyIntro() {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleProceedToSurvey = () => {
        navigate('/survey/survey1');
    };

    return (
        <Container>
            <SurveyIntroContainer>
                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {t('start')}
                </ReactMarkdown>
            </SurveyIntroContainer>
            <Button onClick={handleProceedToSurvey}>{t('proceed')}</Button>
        </Container>


    );
}

export default SurveyIntro;