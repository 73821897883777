import React, { createContext, useState, useCallback, useEffect } from 'react';

export const TrackingContext = createContext();

export const TrackingProvider = ({ children }) => {
    const [buttonClicks, setButtonClicks] = useState([]);
    const [startTime] = useState(Date.now());

    useEffect(() => {
        const storedClicks = JSON.parse(sessionStorage.getItem('buttonClicks')) || [];
        setButtonClicks(storedClicks);
    }, []);

    const addButtonClick = useCallback((clickData) => {
        setButtonClicks(prevClicks => [...prevClicks, clickData]);
    }, []);

    const getTimeTaken = useCallback(() => {
        return Date.now() - startTime;
    }, [startTime]);

    const value = {
        buttonClicks,
        addButtonClick,
        getTimeTaken,
    };

    return (
        <TrackingContext.Provider value={value}>
            {children}
        </TrackingContext.Provider>
    );
};