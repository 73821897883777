import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Container, Title, Subtitle } from '../globalStyle';
import { saveEndingStatus, saveIPAddress } from '../utils/storage';
import styled from 'styled-components';
import { sendSessionData } from '../utils/api';
import LoadingSpinner from '../components/LoadingSpinner';

const Button = styled.button`
    background-color: #4A90E2;
    color: white;
    font-size: 1rem;
    margin-top: 6vh;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 150px;
    &:hover {
        background-color: #357ABD;
    }
`;

const Message = styled.p`
    margin-top: 20px;
    font-size: 1rem;
    color: ${props => props.error ? 'red' : 'green'};
`;


const End = () => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const completionCode = 'C13Y0AUA';
    const redirectUrl = 'https://app.prolific.com/submissions/complete?cc=C13Y0AUA';
    const currentLang = sessionStorage.getItem('language');


    const sendData = async () => {
        try {
            const response = await fetch('https://api.ipify.org?format=json');
            const data = await response.json();
            const ipAddress = data.ip;

            saveEndingStatus('fail');
            saveIPAddress(ipAddress);

            await sendSessionData(sessionStorage);
            setIsLoading(false);
            setTimeout(() => window.location.replace(redirectUrl), 6000);
        } catch (error) {
            console.error('Failed to send session data:', error);
            setError('Failed to send session data. Please download your data using the button below. Your code is C1ODGECF. Please RETURN your study in profilic.');
            setIsLoading(false);
        }
    };

    const handleDownload = () => {
        const sessionData = JSON.stringify(sessionStorage);
        const blob = new Blob([sessionData], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'session_data.json';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };


    sendData();

    return (
        <Container>
            <Title>{t('taskEndTitle')}</Title>
            {isLoading ? (
                <>
                    <LoadingSpinner />
                    <Message>{currentLang === 'en' ? 'Please wait while we upload your data...' : '请等待，我们正在上传您的数据...'}</Message>
                </>
            ) : error ? (
                <>
                    <Message error>{currentLang === 'en' ? `Data upload failed. Please download your data using the button below. Your completion code is ${completionCode}. Please RETURN your study in Prolific.` : `数据上传失败。请使用下面的按钮下载您的数据。您的完成代码是${completionCode}。请在Prolific返回您的研究。`}</Message>
                    <Button onClick={handleDownload}>{currentLang === 'en' ? 'Download data' : '下载数据'}</Button>
                </>
            ) : (
                <Message>
                    {currentLang === 'en' ? `Data uploaded successfully! Your completion code is ${completionCode}. You will be redirected to Prolific in a moment...` : `数据上传成功！您的完成代码是${completionCode}。您将被重定向到Prolific。`}
                </Message>
            )}
        </Container>
    );
};

export default End;