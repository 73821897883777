import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Title, StyledMarkdown, AgreeButton } from '../globalStyle';
import remarkGfm from 'remark-gfm';
import { usePageLoadTracking } from '../hooks/usePageLoadTracking';
import { useButtonTracking } from '../hooks/useButtonTracking';
import { useLocation } from 'react-router-dom';

// import { completeStep } from '../hooks/useFlowControl';

export const ConsentContainer = styled.div`
  width: 40%;
  height: 60vh;
  overflow-y: auto;
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 30px;
  margin-bottom: 30px;

  @media (max-width: 1200px) {
    width: 70%;
    height: 50vh;
  }
`;

function Consent() {
    const [isScrolled, setIsScrolled] = useState(false);
    const contentRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const trackButtonClick = useButtonTracking();
    const currentLang = sessionStorage.getItem('language');

    usePageLoadTracking('consent');


    useEffect(() => {
        const handleScroll = () => {
            if (contentRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
                if (scrollHeight <= clientHeight) {
                    setIsScrolled(true);
                } else if (scrollTop + clientHeight >= scrollHeight - 10) {
                    setIsScrolled(true);
                }
            }
        };

        // Check initial scroll state
        handleScroll();

        const currentRef = contentRef.current;
        if (currentRef) {
            currentRef.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const handleAgree = () => {
        trackButtonClick('consentAgree');
        if (isScrolled) {
            if (currentLang === "en") {
                navigate('/survey-intro');
            } else {
                navigate('/personalinfo')
            }
        }
    };

    return (
        <Container>
            <Title>{t('informedConsentForm')}</Title>
            <ConsentContainer ref={contentRef}>
                <StyledMarkdown remarkPlugins={[remarkGfm]}>
                    {t('consentContent')}
                </StyledMarkdown>
            </ConsentContainer>
            <AgreeButton onClick={handleAgree} disabled={!isScrolled}>
                {t('agree')}
            </AgreeButton>
        </Container>
    );
}

export default Consent;