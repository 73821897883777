import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { quizQuestions } from '../locales/quizQuestions';
import { saveQuizAnswers, getQuizAnswers } from '../utils/storage';
import { Container, ContentContainer, Title, Subtitle, AgreeButton } from '../globalStyle';
import { useButtonTracking } from '../hooks/useButtonTracking';

const QuizContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 8px;
  max-height: 80%;
  max-width: 50%;
  overflow-y: auto;
`;

const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 90%;
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    margin-bottom: 20px;
  }

  @media (max-width: 480px) {
    margin-bottom: 10px;
  }
`;

const QuestionText = styled.p`
  font-size: clamp(0.8rem, 1.2rem, 1.5rem);
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
`;

const AnswerButton = styled.button`
  padding: 5px 15px;
  font-size: 0.9rem;
  background-color: ${props => props.selected ? '#4A90E2' : '#f0f4f8'};
  color: ${props => props.selected ? 'white' : 'black'};
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background-color: #4A90E2;
    color: white;
  }

  @media (max-width: 768px) {
    font-size: 0.6rem;
  }

  @media (max-width: 480px) {
    font-size: 0.4rem;
  }
`;

const AlertMessage = styled.div`
  background-color: #ffcccc;
  color: #cc0000;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
`;

const Quiz = () => {
  const [answers, setAnswers] = useState({});
  const [attempts, setAttempts] = useState(() => {
    const savedAttempts = sessionStorage.getItem('quizAttempts');
    return savedAttempts ? parseInt(savedAttempts, 10) : 0;
  });
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const currentLang = sessionStorage.getItem('language');
  const questions = quizQuestions[currentLang] || quizQuestions.en;
  const trackButtonClick = useButtonTracking();

  const handleAnswer = (questionId, answer) => {
    setAnswers(prev => ({ ...prev, [questionId]: answer }));
  };

  const handleFinish = () => {
    const correctAnswers = questions.filter(q => answers[q.id] === q.correctAnswer).length;
    const newAttempts = attempts + 1;
    setAttempts(newAttempts);
    sessionStorage.setItem('quizAttempts', newAttempts.toString());
    saveQuizAnswers(answers, newAttempts);

    trackButtonClick(`quiz-${newAttempts}`);

    if (correctAnswers < 4) {
      setShowAlert(true);
      if (newAttempts === 1) {
        // First attempt failed
        sessionStorage.setItem('quizStatus', 'retake');
        setTimeout(() => {
          navigate('/survey-completed');
        }, 3000); // Navigate after 3 seconds
      } else {
        // Second attempt failed
        sessionStorage.setItem('quizStatus', 'fail');
        setTimeout(() => {
          navigate('/end');
        }, 3000); // Navigate after 3 seconds
      }
    } else {
      // Passed the quiz
      sessionStorage.setItem('quizStatus', 'pass');
      navigate('/view-objects');
    }
  };

  useEffect(() => {
    const currentLang = sessionStorage.getItem('language');
    if (currentLang) {
      i18n.changeLanguage(currentLang);
    }
  }, [i18n]);

  return (
    <Container>
      {showAlert && (
        <AlertMessage>
          {attempts === 1
            ? t('quizFailFirstAttempt')
            : t('quizFailSecondAttempt')}
        </AlertMessage>
      )}
      <ContentContainer>
        <QuizContainer>
          <Subtitle>{t('quizGuide')}</Subtitle>
          {questions.map(question => (
            <QuestionContainer key={question.id}>
              <QuestionText>{question.text}</QuestionText>
              <ButtonContainer>
                <AnswerButton
                  selected={answers[question.id] === true}
                  onClick={() => handleAnswer(question.id, true)}
                >
                  {t('true')}
                </AnswerButton>
                <AnswerButton
                  selected={answers[question.id] === false}
                  onClick={() => handleAnswer(question.id, false)}
                >
                  {t('false')}
                </AnswerButton>
              </ButtonContainer>
            </QuestionContainer>
          ))}
        </QuizContainer>
        <AgreeButton disabled={Object.keys(answers).length !== questions.length} onClick={handleFinish}>
          {t('finish')}
        </AgreeButton>
      </ContentContainer>
    </Container>
  );
};

export default Quiz;