import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Title, Subtitle, ContentContainer } from '../globalStyle';
import { getUserSetting, isHumanSetting, isManySetting } from '../utils/userSettings';
import { objectsData_en, objectsData_zh } from '../locales/taskObjects';
import { useButtonTracking } from '../hooks/useButtonTracking';
import { TrackingContext } from '../contexts/TrackingContext';
import { saveObjects } from '../utils/storage';


const SubtitleContainer = styled.div`
  margin-top: 10vh;
  max-width: 60%;
`;

const ObjectsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  width: 80%;

  @media (max-width: 768px) {
    margin-top: 0vh;
    max-height: 100%;
    width: 100%;
  }
`;

const ObjectGrid = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
  gap: 10px;
  width: 100%;
  max-height: 80%;
  margin-top: 10vh;

  @media (max-width: 768px) {
    grid-template-rows: 1fr 1fr;
  }
`;

const ObjectItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const ObjectImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 6rem;
  max-height: 6rem;
  object-fit: contain;
  margin-bottom: 5px;

  @media (max-width: 768px) {
    max-width: 4rem;
    max-height: 4rem;
  }
`;

const ObjectName = styled.p`
  font-size: 0.8rem;
  color: #333;

  @media (max-width: 768px) {
    font-size: 0.3rem;
  }
`;

const NavigationButton = styled.button`
  background: none;
  border: none;
  font-size: clamp(2rem, 6rem, 7rem);
  cursor: pointer;
  color: #4A90E2;
  padding: 0 10px;

  &:disabled {
    color: #ccc;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const ProceedButton = styled.button`
  background-color: ${props => props.disabled ? '#ccc' : '#4A90E2'};
  color: white;
  border: none;
  padding: clamp(8px, 2vw, 10px) clamp(15px, 3vw, 20px);
  font-size: clamp(0.8rem, 2vw, 1rem);
  border-radius: 5px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  transition: background-color 0.3s ease;
  margin-top: 10vh;

  &:hover {
    background-color: ${props => props.disabled ? '#ccc' : '#357ABD'};
  }
`;

const ViewObjects = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [objects, setObjects] = useState([]);
  const [currentGroup, setCurrentGroup] = useState(0);
  const [countdown, setCountdown] = useState(30);
  const [countDownFinished, setCountDownFinished] = useState(false);
  const [canProceed, setCanProceed] = useState(false);
  const [hasViewedAllGroups, setHasViewedAllGroups] = useState(false);
  const currentLang = sessionStorage.getItem('language');

  const trackButtonClick = useButtonTracking();
  const { getTimeTaken, buttonClicks } = useContext(TrackingContext);

  useEffect(() => {
    const currentLang = sessionStorage.getItem('language');
    if (currentLang) {
      i18n.changeLanguage(currentLang);
    }
  }, [i18n]);

  useEffect(() => {
    const userSetting = getUserSetting();
    const isHuman = isHumanSetting(userSetting);
    const isMany = isManySetting(userSetting);

    const objectsData = currentLang === 'en' ? objectsData_en : objectsData_zh;

    let selectedObjects = isMany ? objectsData.many : objectsData.few;
    if (isHuman) {
      selectedObjects = [...selectedObjects, ...objectsData.human];
    } else if (!isHuman) {
      selectedObjects = [...selectedObjects, ...objectsData.dummy];
    }

    // Randomize the order of selected objects
    selectedObjects = selectedObjects.sort(() => Math.random() - 0.5);

    setObjects(selectedObjects);
    saveObjects(selectedObjects);

  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown === 1) {
          clearInterval(timer);
          setCountDownFinished(true);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (currentGroup === 1) {
      setHasViewedAllGroups(true);
    }
  }, [currentGroup, objects]);

  useEffect(() => {
    if (countDownFinished && hasViewedAllGroups) {
      setCanProceed(true);
    }
  }, [countDownFinished, hasViewedAllGroups]);

  const handlePrevGroup = () => {
    trackButtonClick('viewObjectsPrevGroup');
    setCurrentGroup(prev => Math.max(0, prev - 1));
  };

  const handleNextGroup = () => {
    trackButtonClick('viewObjectsNextGroup');
    setCurrentGroup(prev => Math.min(Math.ceil(objects.length / 14) - 1, prev + 1));
  };

  const handleProceed = () => {
    trackButtonClick('viewObjectsProceed');
    const timeTaken = getTimeTaken();
    console.log('Time taken:', timeTaken, 'ms');
    console.log('Button click history:', buttonClicks);
    navigate('/task');
  };

  return (
    <Container>
      <SubtitleContainer>
        <Subtitle>{t('viewObjectsInstruction')}</Subtitle>
      </SubtitleContainer>
      <ContentContainer>
        <ObjectsContainer>
          <NavigationButton onClick={handlePrevGroup} disabled={currentGroup === 0}>
            &#8592;
          </NavigationButton>
          <ObjectGrid>
            {objects.slice(currentGroup * (objects.length / 2), (currentGroup + 1) * (objects.length / 2)).map(object => (
              <ObjectItem key={object.id}>
                <ObjectImage src={`/images/${object.icon}`} alt={object.name} />
                <ObjectName>{object.name} × {object.count}</ObjectName>
              </ObjectItem>
            ))}
          </ObjectGrid>
          <NavigationButton onClick={handleNextGroup} disabled={currentGroup === 1}>
            &#8594;
          </NavigationButton>
        </ObjectsContainer>
        <ProceedButton
          onClick={handleProceed}
          disabled={!canProceed}
        >
          {countDownFinished
            ? t('proceedToTask')
            : `${t('pleaseWait')} (${countdown}s)`
          }
        </ProceedButton>
      </ContentContainer>
    </Container>
  );
};

export default ViewObjects;