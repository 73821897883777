import { useCallback, useContext } from 'react';
import { TrackingContext } from '../contexts/TrackingContext';

export const useButtonTracking = () => {
    const { addButtonClick } = useContext(TrackingContext);

    const trackButtonClick = useCallback((buttonType) => {
        const clickTime = Date.now();
        const clickData = { type: buttonType, time: clickTime };
        addButtonClick(clickData);

        // Save to session storage
        const storedClicks = JSON.parse(sessionStorage.getItem('buttonClicks')) || [];
        storedClicks.push(clickData);
        sessionStorage.setItem('buttonClicks', JSON.stringify(storedClicks));
    }, [addButtonClick]);

    return trackButtonClick;
};