import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Container, Title, AgreeButton, ContentContainer } from '../globalStyle';

import { useButtonTracking } from '../hooks/useButtonTracking';

const FormContainer = styled.div`
  width: 40%;
  margin: 0 auto;
  padding: 1rem;

  @media (max-width: 768px) {
    width: 80%;
    padding: 0.5rem;
  }
`;

const InputGroup = styled.div`
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    margin-bottom: 0.75rem;
  }
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
    margin-bottom: 0.3rem;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;

  @media (max-width: 768px) {
    padding: 0.4rem;
    font-size: 0.9rem;
  }
`;

const Select = styled.select`
  max-width: 100vw;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;

  @media (max-width: 768px) {
    padding: 0.4rem;
    font-size: 0.9rem;
  }
`;

function PersonalInfo() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        gender: '',
        studentId: ''
    });
    const [isFormValid, setIsFormValid] = useState(false);
    const trackButtonClick = useButtonTracking();

    useEffect(() => {
        const isValid = Object.values(formData).every(value => value.trim() !== '');
        setIsFormValid(isValid);
    }, [formData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = () => {
        if (isFormValid) {
            sessionStorage.setItem('personalInfo', JSON.stringify(formData));
            trackButtonClick('personalInfoProceed');
            navigate('/survey-intro');
        }
    };

    return (
        <Container>
            <ContentContainer>
                <Title>{t('personalInfoCollection')}</Title>
                <FormContainer>
                    <InputGroup>
                        <Label htmlFor="name">{t('yourName')}</Label>
                        <Input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                        />
                    </InputGroup>
                    <InputGroup>
                        <Label htmlFor="mobile">{t('yourMobile')}</Label>
                        <Input
                            type="tel"
                            id="mobile"
                            name="mobile"
                            value={formData.mobile}
                            onChange={handleInputChange}
                        />
                    </InputGroup>
                    <InputGroup>
                        <Label htmlFor="gender">{t('gender')}</Label>
                        <Select
                            id="gender"
                            name="gender"
                            value={formData.gender}
                            onChange={handleInputChange}
                        >
                            <option value="">{t('selectGender')}</option>
                            <option value="male">{t('male')}</option>
                            <option value="female">{t('female')}</option>
                        </Select>
                    </InputGroup>

                    <InputGroup>
                        <Label htmlFor="age">{t('age')}</Label>
                        <Input
                            type="text"
                            id="age"
                            name="age"
                            value={formData.age}
                            onChange={handleInputChange}
                        />
                    </InputGroup>

                    <InputGroup>
                        <Label htmlFor="studentId">{t('studentId')}</Label>
                        <Input
                            type="text"
                            id="studentId"
                            name="studentId"
                            value={formData.studentId}
                            onChange={handleInputChange}
                        />
                    </InputGroup>
                </FormContainer>
                <AgreeButton onClick={handleSubmit} disabled={!isFormValid}>
                    {t('proceed')}
                </AgreeButton>
            </ContentContainer>
        </Container>
    );
}

export default PersonalInfo;