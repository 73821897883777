export const surveyFlow = [
    'survey1',
    'survey2',
    'survey3',
    'survey4',
];

export const getNextSurvey = (currentSurveyId) => {
    const currentIndex = surveyFlow.indexOf(currentSurveyId);
    if (currentIndex < surveyFlow.length - 1) {
        return surveyFlow[currentIndex + 1];
    }
    return null; // Indicates all surveys are completed
};