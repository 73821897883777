import React from 'react';
import styled from 'styled-components';
import { MatrixHeader, MatrixHeaderItem, MatrixRow, MatrixQuestion, MatrixChoices, MatrixChoice, QuestionText } from '../globalStyle';

const MatrixContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-bottom: 15px;
`;



const Matrix = ({ tasks, matrixAnswers, setMatrixAnswers, title, scaleLabels, matrixType }) => {
    const handleMatrixSelect = (taskName, value) => {
        setMatrixAnswers(prev => {
            const newAnswers = { ...prev };
            newAnswers[`${matrixType}_${taskName}`] = value;
            return newAnswers;
        });
    };

    return (
        <>
            <QuestionText>{title}</QuestionText>
            <MatrixContainer>
                <MatrixHeader>
                    <MatrixHeaderItem></MatrixHeaderItem>
                    {scaleLabels.map((label, index) => (
                        <MatrixHeaderItem key={index}>{label}</MatrixHeaderItem>
                    ))}
                </MatrixHeader>
                {tasks.map((task, index) => (
                    <MatrixRow key={index}>
                        <MatrixQuestion>{task}</MatrixQuestion>
                        <MatrixChoices>
                            {scaleLabels.map((_, scaleIndex) => (
                                <MatrixChoice
                                    key={scaleIndex}
                                    type="radio"
                                    name={`${matrixType}-${task}`}
                                    checked={matrixAnswers[`${matrixType}_${task}`] === scaleIndex.toString()}
                                    onChange={() => handleMatrixSelect(task, scaleIndex.toString())}
                                />
                            ))}
                        </MatrixChoices>
                    </MatrixRow>
                ))}
            </MatrixContainer>
        </>
    );
};

export default Matrix;