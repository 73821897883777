import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Container, Title, AgreeButton, ContentContainer } from '../globalStyle';
import { useButtonTracking } from '../hooks/useButtonTracking';

const TextArea = styled.textarea`
  width: 60%;
  height: 50vh;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical;
`;

const Feedback = () => {
    const [feedback, setFeedback] = useState('');
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const trackButtonClick = useButtonTracking();

    const handleSubmit = () => {
        sessionStorage.setItem('userFeedback', feedback);
        trackButtonClick('feedbackSubmit');
        navigate('/thank-you');
    };

    useEffect(() => {
        const currentLang = sessionStorage.getItem('language');
        if (currentLang) {
            i18n.changeLanguage(currentLang);
        }
    }, [i18n]);


    return (
        <Container>
            <ContentContainer>
                <Title>{t('feedbackTitle')}</Title>
                <TextArea
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    placeholder={t('feedbackPlaceholder')}
                />
                <AgreeButton onClick={handleSubmit}>
                    {t('submit')}
                </AgreeButton>
            </ContentContainer>
        </Container>
    );
};

export default Feedback;