import React, { useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';



const isValidNavigation = (currentStep, lastCompletedStep) => {
    const currentLang = sessionStorage.getItem('language');
    const flowOrder = currentLang === "zh" ? [
        '/', '/consent', '/personalinfo', '/survey-intro',
        '/survey/survey1', '/survey/survey2', '/survey/survey3', '/survey/survey4',
        '/survey-completed', '/quiz', '/view-objects', '/task', '/evaluation', '/feedback', '/thank-you'
    ] : [
        '/', '/consent', '/survey-intro',
        '/survey/survey1', '/survey/survey2', '/survey/survey3', '/survey/survey4',
        '/survey-completed', '/quiz', '/view-objects', '/task', '/evaluation', '/feedback', '/thank-you'
    ];

    console.log(currentLang);
    console.log(flowOrder);

    const currentIndex = flowOrder.indexOf(currentStep);
    const lastIndex = flowOrder.indexOf(lastCompletedStep);
    if (currentStep === "/survey-completed") {
        return true;
    }

    if (lastCompletedStep === '/quiz') {
        if (sessionStorage.getItem('quizStatus') === 'retake') {
            return currentStep === '/survey-completed';
        } else if (sessionStorage.getItem('quizStatus') === 'fail') {
            return currentStep === '/end';
        } else if (sessionStorage.getItem('quizStatus') === 'pass') {
            return currentStep === '/view-objects';
        }
    }
    if (currentStep === '/end') {
        return true;
    }
    return currentIndex === lastIndex + 1 || currentIndex === lastIndex;
};

export const useFlowControl = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const currentStep = location.pathname;
        const lastCompletedStep = sessionStorage.getItem('lastCompletedStep');
        console.log(sessionStorage);
        console.log(currentStep, lastCompletedStep, isValidNavigation(currentStep, lastCompletedStep));
        if (!isValidNavigation(currentStep, lastCompletedStep)) {
            // if (window.confirm('Are you sure you want to leave this page? All progress will be lost.')) {
            //     navigate('/');
            //     sessionStorage.clear();
            // } else {
            //     const currentIndex = flowOrder.indexOf(currentStep) + 1;
            //     sessionStorage.setItem('lastCompletedStep', flowOrder[currentIndex]);
            //     navigate(flowOrder[currentIndex]);
            // }
            navigate('/');
            sessionStorage.clear();
        } else {
            sessionStorage.setItem('lastCompletedStep', currentStep);
        }

    }, [location, navigate]);
};
