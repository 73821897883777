import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Title, Subtitle, ButtonContainer, Button } from '../globalStyle';

function Home() {
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();

    const handleLanguageSelect = (lang) => {
        i18n.changeLanguage(lang);
        sessionStorage.setItem('language', lang);
        navigate('/consent');
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const prolificId = searchParams.get('PROLIFIC_PID');
        if (prolificId) {
            sessionStorage.setItem('PROLIFIC_PID', prolificId);
        }

    }, [location.search]);


    return (
        <Container>
            <Title> Welcome / 欢迎</Title>
            <Subtitle>Please select your language / 请选择您的语言</Subtitle>
            <ButtonContainer>
                <Button onClick={() => handleLanguageSelect('en')}>English</Button>
                <Button onClick={() => handleLanguageSelect('zh')}>中文</Button>
            </ButtonContainer>
        </Container>
    );
}

export default Home;