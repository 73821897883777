// Survey answers
export const saveSurveyAnswers = (surveyId, answers) => {
    const key = `surveyAnswers-${surveyId}`;
    sessionStorage.setItem(key, JSON.stringify(answers));
};

export const getSurveyAnswers = (surveyId) => {
    const key = `surveyAnswers-${surveyId}`;
    return JSON.parse(sessionStorage.getItem(key) || '{}');
};

// Quiz answers
export const saveQuizAnswers = (answers, attempt) => {
    const key = `quizAnswers`;
    const allQuizAnswers = JSON.parse(sessionStorage.getItem(key) || '{}');
    allQuizAnswers[`Attempt${attempt}`] = answers;
    sessionStorage.setItem(key, JSON.stringify(allQuizAnswers));
};

export const getQuizAnswers = () => {
    const key = `quizAnswers`;
    return JSON.parse(sessionStorage.getItem(key) || '{}');
};

// Objects
export const saveObjects = (objects) => {
    const key = 'objects';
    sessionStorage.setItem(key, JSON.stringify(objects));
};

export const getObjects = () => {
    const key = 'objects';
    return JSON.parse(sessionStorage.getItem(key) || '[]');
};

// Task inputs
export const saveTaskInputs = (taskInputs) => {
    const key = 'taskInputs';
    const allTaskInputs = JSON.parse(sessionStorage.getItem(key) || '[]');
    allTaskInputs.push(taskInputs);
    sessionStorage.setItem(key, JSON.stringify(allTaskInputs));
};

export const getTaskInputs = () => {
    const key = 'taskInputs';
    return JSON.parse(sessionStorage.getItem(key) || '[]');
};

// Completed task names
export const saveCompletedTaskName = (taskName) => {
    const key = 'completedTaskNames';
    const completedTaskNames = JSON.parse(sessionStorage.getItem(key) || '[]');
    completedTaskNames.push(taskName);
    sessionStorage.setItem(key, JSON.stringify(completedTaskNames));
};

export const getCompletedTaskNames = () => {
    const key = 'completedTaskNames';
    return JSON.parse(sessionStorage.getItem(key) || '[]');
};

export const clearCompletedTaskNames = () => {
    sessionStorage.removeItem('completedTaskNames');
};

// Save ending status
export const saveEndingStatus = (endingStatus) => {
    sessionStorage.setItem('endingStatus', endingStatus);
};


// Task evaluationInputs
export const saveEvaluationInputs = (evaluationInputs) => {
    const key = 'evaluationInputs';
    sessionStorage.setItem(key, JSON.stringify(evaluationInputs));
};


export const saveIPAddress = (ipAddress) => {
    sessionStorage.setItem('ipAddress', ipAddress);
};

export const getIPAddress = () => {
    return sessionStorage.getItem('ipAddress');
};