import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import zhConsent from '../locales/zh-consent.md';
import enConsent from '../locales/en-consent.md';
import enIntro from '../locales/en-experiment-intro.md';
import zhIntro from '../locales/zh-experiment-intro.md';
import enGuide from '../locales/en-experiment-guide.md';
import zhGuide from '../locales/zh-experiment-guide.md';
import enExample from '../locales/en-experiment-example.md';
import zhExample from '../locales/zh-experiment-example.md';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            zh: {
                translation: {
                    "welcome": "欢迎",
                    "selectLanguage": "请选择您的语言",
                    "informedConsentForm": "知情同意表",
                    "consentContent": zhConsent,
                    "agree": "同意",
                    "proceed": "继续",
                    "personalInfoCollection": "个人信息收集",
                    "yourName": "您的姓名（用以发放被试费）",
                    "yourMobile": "您的手机号码（用以发放被试费）",
                    "gender": "性别",
                    "age": "年龄",
                    "education": "教育水平",
                    "studentId": "学号/其他证件号",
                    "selectGender": "请选择您的性别",
                    "male": "男",
                    "female": "女",
                    "selectEducation": "请选择您的教育水平",
                    "survey": "量表",
                    "experiment": "实验介绍",
                    "start": "欢迎参加本实验！在接下来的实验中，您将会看到一些量表，请您根据量表的指示选出**与您最符合**的选项。",
                    "taskEndTitle": "实验结束！很抱歉，您未能通过测试。",
                    "survey_completed": "恭喜您完成了量表的填写！接下来，我们将进入下一个任务。请您想象自己在一个真实的房间中，并使用房间中的物品来消磨时间。您会做什么？请仔细阅读任务介绍。",
                    "experiment_detail_page1": zhIntro,
                    "experiment_detail_page2": zhExample,
                    "experiment_detail_page3": zhGuide,
                    "previous": "上一页",
                    "next": "下一页",

                    "quiz_requirement": "为了确保您理解本实验，在正式实验前，您需要先回答几个与实验设置有关的问题。",
                    "true": '正确',
                    "false": '错误',
                    "finish": '完成',
                    "quizTitle": '任务测试',
                    "quizGuide": '请根据任务介绍判断以下描述是否正确。您需要正确回答4题/4题才能继续进入下一步。',
                    "quizFailFirstAttempt": '您的得分低于4题/4题。请重新阅读任务介绍并再次尝试。即将跳转至任务介绍页面。',
                    "quizFailSecondAttempt": '您的得分再次低于4题/4题。实验现在将结束。即将跳转至实验结束页面。',

                    "viewInstructions": "查看任务要求",
                    "viewExamples": "查看示例",
                    "close": "关闭",
                    "instructionsTitle": "任务介绍",

                    "taskInstruction": "请您根据左侧的物品清单，设计一个任务。当您准备完毕后，请点击下方按钮进入任务创建界面。",
                    "startCreateTask": "开始创建新任务",
                    "continueCreateTask": "继续创建新任务",
                    "taskName": "**任务名称：请为您的任务起一个简短的名字**",
                    "taskDescription": "**任务描述：请用简短的文字描述您所设计的任务**",
                    "neededObjects": "**所需物品：请从物品列表中选出任务所需的所有道具；点击左侧的物品清单以添加**",
                    "detailedSetting": "**任务详细设置：请您描述物品在任务中的摆放和互动方式**",
                    "goalSetting": "**任务目标：请您描述这个任务的目标**",
                    "scoringSystem": "**得分系统：请您描述这个任务的计分方式**",
                    "effortLevel": "**您为了想出这个任务付出了多少努力？**",
                    "difficultyLevel": "**您觉得您的这个任务对于普通成年人来说有多难？**",
                    "scoreEstimation": "**如果满分十分，您觉得您按照描述执行这个任务可以获得多少分？**",
                    "taskNamePlaceholder": "任务名称应少于10个字",
                    "taskDescriptionPlaceholder": "在这个任务中，玩家…",
                    "neededObjectsPlaceholder": "请从物品列表中选出任务所需的所有道具；点击左侧的物品清单以添加",
                    "detailedSettingPlaceholder": "为了开始这个任务，需要…",
                    "goalSettingPlaceholder": "这个任务的目标是…",
                    "scoringSystemPlaceholder": "在这个任务中…",

                    "selectOption": "请选择",
                    "difficulty1": "毫不费力",
                    "difficulty2": "非常简单",
                    "difficulty3": "简单",
                    "difficulty4": "中等难度",
                    "difficulty5": "困难",
                    "difficulty6": "非常困难",
                    "difficulty7": "极度困难",

                    "effort1": "毫不费力",
                    "effort2": "非常轻松",
                    "effort3": "轻松",
                    "effort4": "一般",
                    "effort5": "困难",
                    "effort6": "非常困难",
                    "effort7": "竭尽全力",

                    "viewObjects": "物品清单",
                    "viewObjectsInstruction": "恭喜你回答正确，接下来我们进入正式实验阶段。屏幕下方是一个真实房间中的物品列表，请您逐个浏览。",
                    "pleaseWait": "请浏览",
                    "proceedToTask": "继续",
                    "fullScorePlaceholder": "满分",
                    "estimatedScorePlaceholder": "得分",

                    "initialInstruction": "请您根据左侧的物品清单，结合您的个人经验与偏好，设计您的任务（一个活动或游戏）。在您想出**任意满足条件**的任务之后，请点击下方按钮进入任务创建界面。",
                    "tasksCompleted": "恭喜您完成了第{{count}}个任务！现在，请尽量想出更多新的活动或游戏，每想到一个就立即报告并写下来。注意，您报告的任务不能与之前的任务完全相同（至少3个任务，完成后点击“结束”退出）。",
                    "finishTask": "继续",
                    "exit": "结束",
                    "taskNameLengthError": "任务名称长度应在6到10个字符之间",
                    "noNeededObjectsError": "请至少选择一个所需物品",
                    "duplicateTaskNameError": "任务名称不能重复",
                    "taskDescriptionLengthError": "任务描述长度应大于6个字符",
                    "scoringSystemLengthError": "得分系统不能为空",
                    "detailedSettingLengthError": "任务详细设置不能为空",
                    "goalSettingLengthError": "任务目标不能为空",
                    "estimatedScoreLengthError": "得分估计不能为空",


                    "evaluation": "恭喜您填写完毕，以下是您所想象出的任务：",
                    "evalSortTaskTitle": "1. 请将您设计的任务按您希望执行的顺序进行排序",
                    "evalCharacteristicsTitle": "2. 您在想象任务时考虑了任务的哪些特性？",
                    "evalStrategyTitle": "3. 您在思考任务时主要依赖什么策略？",
                    "evalNoveltyTitle": "4. 请您对实验场景下每个任务的新颖程度进行评分",
                    "evalLikelihoodTitle": "5. 当您处在本实验的真实房间中时，您有多大的可能会去做这些任务？",
                    "evalNoticeOtherPeopleTitle": "6. 您是否注意到了场景中的其他人？",
                    "evalNoticeDummyTitle": "6. 您是否注意到了场景中的人偶？",
                    "evalConsiderOtherPeopleTitle": "7. 您在设想任务时多大程度上考虑了场景中的其他人？",
                    "evalConsiderDummyTitle": "7. 您在设想任务时多大程度上考虑了场景中的人偶？",
                    "others": "其他：请填写",

                    "evalNoveltyScales": '非常不新颖, ,不太新颖, ,比较新颖, ,非常新颖',
                    "evalLikelihoodScales": '非常不可能, ,有点不可能, ,比较可能, ,非常可能',
                    "evalNoticeChoices": '是,否',
                    "evalConsiderScales": '非常少,很少,比较少,中等,比较多,很多,非常多',


                    "feedbackTitle": "反馈",
                    "feedbackPlaceholder": "您对本实验有何建议？（可选）",
                    "submit": "提交",

                }
            },
            en: {
                translation: {
                    "welcome": "Welcome",
                    "selectLanguage": "Please select your language",
                    "informedConsentForm": "Informed Consent Form",
                    "consentContent": enConsent,
                    "agree": "Agree",
                    "proceed": "Proceed",
                    "personalInfoCollection": "Personal Information Collection",
                    "yourName": "Your Name (for participant payment)",
                    "yourMobile": "Your Mobile Number (for participant payment)",
                    "gender": "Sex",
                    "age": "Age",
                    "education": "Education Level",
                    "studentId": "Student ID/Other ID Number",
                    "selectGender": "Please select your sex",
                    "male": "Male",
                    "female": "Female",
                    "selectEducation": "Please select your education level",
                    "survey": "Survey",
                    "survey1Title": "Thinking and Working Style scale",
                    "survey2Title": "AQ-Short",
                    "survey3Title": "Emotional State Scale",
                    "experiment": "Experiment Introduction",
                    "start": "Welcome to this experiment! In the following tasks, you will see some scales. Please select the options that **best describe you** according to the instructions of each scale。",
                    "taskEndTitle": "Experiment End! Sorry, you failed to pass the test.",
                    "survey_completed": "Congratulations, you have completed the survey. Next, we will move to the following task. Imagine you are in a real room, and you need to use the items in the room to pass some time. What would you do? Please read the task introduction below carefully.",
                    "experiment_detail_page1": enIntro,
                    "experiment_detail_page2": enExample,
                    "experiment_detail_page3": enGuide,
                    "previous": "Previous",
                    "next": "Next",


                    "quiz_requirement": "Before starting the formal experiment, to ensure you understand the experiment, you need to answer several questions about the experimental setup.",
                    "true": 'True',
                    "false": 'False',
                    "finish": 'Finish',
                    "quizTitle": 'Task Quiz',
                    "quizGuide": 'Based on the task introduction, judge whether the following statements are correct. You need to correctly answer 4 out of 4 questions to proceed',
                    "quizFailFirstAttempt": 'You scored less than 4/4. Please review the task introduction and try again.',
                    "quizFailSecondAttempt": 'You scored less than 4/4 again. The experiment will now end.',
                    "viewInstructions": "View Task Instructions",
                    "viewExamples": "View Task Examples",
                    "close": "Close",
                    "instructionsTitle": "Task Instructions",
                    "taskInstruction": "Please design a task based on the list of items on the left. Once you are ready, click the button below to enter the task creation interface.",
                    "startCreateTask": "Start Creating a New Task",
                    "continueCreateTask": "Continue Creating a New Task",
                    "taskName": "**Task Name: Please give your task a short name**",
                    "taskDescription": "**Task Description: Please briefly describe the task you designed**",
                    "neededObjects": "**Required Items: Please select all necessary items from the item list; click on the item list on the left to add them**",
                    "detailedSetting": "**Detailed Task Setup: Please describe how the items are arranged and interacted with in the task**",
                    "goalSetting": "**Task Goal: Please describe the goal of the task**",
                    "scoringSystem": "**Scoring System: Please describe the scoring method for the task**",
                    "effortLevel": "**How much effort did you put into coming up with this task?**",
                    "difficultyLevel": "**How difficult do you think this task would be for an average adult?**",
                    "scoreEstimation": "**On a scale of 10, how many points do you think you could earn by completing this task as described?**",
                    "taskNamePlaceholder": "The task name should be fewer than 6 words",
                    "taskDescriptionPlaceholder": "In this task, the player...",
                    "neededObjectsPlaceholder": "Please select all necessary items from the item list; click on the item list on the left to add them",
                    "detailedSettingPlaceholder": "To start this task, player need...",
                    "goalSettingPlaceholder": "The goal of this task is...",
                    "scoringSystemPlaceholder": "In this task...",
                    "selectOption": "Please select",
                    "difficulty1": "Effortless",
                    "difficulty2": "Very easy",
                    "difficulty3": "Easy",
                    "difficulty4": "Moderate difficulty",
                    "difficulty5": "Difficult",
                    "difficulty6": "Very difficult",
                    "difficulty7": "Extremely difficult",

                    "effort1": "Effortless",
                    "effort2": "Very easy",
                    "effort3": "Easy",
                    "effort4": "Moderate",
                    "effort5": "Difficult",
                    "effort6": "Very difficult",
                    "effort7": "Exhausting",

                    "viewObjects": "Item List",
                    "viewObjectsInstruction": "Congratulations! Now we enter the formal experiment phase. Below the screen is a list of items from a real room. Please browse through them one by one.",
                    "pleaseWait": "Please browse",
                    "proceedToTask": "Proceed",
                    "fullScorePlaceholder": "Full score",
                    "estimatedScorePlaceholder": "Estimated score",

                    "initialInstruction": "Please design your task (a daily activity or game) based on the list of items on the left and your personal experience and preferences. Once you come up with **any task that meets the requirements**, click the button below to enter the task creation interface.",
                    "tasksCompleted": "Congratulations on completing task {{count}}! Now, please come up with as many new tasks (daily activities or games) as possible. Write down each one as you think of it. Note that your reported tasks cannot be identical to previous ones (at least 3 tasks; click Finish to exit after completing).",
                    "finishTask": "Continue",
                    "exit": "Finish",
                    "taskNameLengthError": "The task name should be fewer than 6 words and not empty",
                    "noNeededObjectsError": "Please select at least one required item",
                    "duplicateTaskNameError": "Task name cannot be repeated",
                    "taskDescriptionLengthError": "Task description must be more than 6 words",
                    "scoringSystemLengthError": "Scoring system cannot be empty",
                    "detailedSettingLengthError": "Detailed task setup cannot be empty",
                    "goalSettingLengthError": "Task goal cannot be empty",
                    "estimatedScoreLengthError": "Score estimation cannot be empty",
                    "evaluation": "Congratulations, you have completed the task. Here are the tasks you imagined:",

                    "evalSortTaskTitle": "1. Please sort the tasks in the order you would like to perform them.",
                    "evalCharacteristicsTitle": "2. What characteristics of the task did you consider when thinking?",
                    "evalStrategyTitle": "3. What strategies do you rely on when thinking about tasks?",
                    "evalNoveltyTitle": "4. Please rate the degree of novelty of each task in the experimental scenario",
                    "evalLikelihoodTitle": "5. How likely would you be to perform these tasks if you were in the real room from the experiment?",
                    "evalNoticeOtherPeopleTitle": "6. Did you notice other people in the scenario?",
                    "evalNoticeDummyTitle": "6. Did you notice the dummy in the scenario?",
                    "evalConsiderOtherPeopleTitle": "7. To what extent did you consider other people in the scenario when imagining the tasks?",
                    "evalConsiderDummyTitle": "7. To what extent did you consider the dummy in the scenario when imagining the tasks?",
                    "others": "Others",

                    "evalNoveltyScales": 'Not novel at all, ,Not very novel, ,Somewhat novel, , Very novel',
                    "evalLikelihoodScales": 'Very unlikely, , Somewhat unlikely, ,Somewhat likely, ,Very likely',
                    "evalNoticeChoices": 'Yes,No',
                    "evalConsiderScales": 'Very little,Little,Somewhat little,Moderate,Somewhat much,Much,Very much',

                    "feedbackTitle": "Feedback",
                    "feedbackPlaceholder": "Do you have any suggestions for improving this experiment? (Optional)",
                    "submit": "Submit",


                }
            }
        },
        lng: "en",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;