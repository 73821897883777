import { useEffect } from 'react';

export const usePageLoadTracking = (pageName) => {
    useEffect(() => {
        const loadTime = Date.now();
        const pageLoadData = { page: pageName, time: loadTime };

        // Save to session storage
        const storedPageLoads = JSON.parse(sessionStorage.getItem('pageLoads')) || [];
        storedPageLoads.push(pageLoadData);
        sessionStorage.setItem('pageLoads', JSON.stringify(storedPageLoads));
        // console.log(`Page loaded: ${pageName} at ${new Date(loadTime).toLocaleString()}`);
    }, [pageName]);
};